import { List, ListItem } from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import useChatSocketHandlers from "../../Context/SocketHandle";
import MessageSidebarCard from "../../Pages/Messaging/individual/MessageSidebarCard";
import { useGetExternalData } from "../../Web-Hooks/Chat/chat";

const DirectMessageNav = ({
  selectedWorkspace,
  selectedSubWorkspace,
  setUnreadCount,
}) => {
  const [chattedUserList, setChattedUserList] = useState([]);

  const location = useLocation();
  const pathname = location.pathname;
  const { user: loggedInUser } = useAuth();
  const forumOnly = process.env.REACT_APP_FORUM_WISE === "true";

  const { data: chatUserList } = useGetExternalData(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId,
    forumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
    "",
    true,
    (data) => data.data.data.filter((user) => user.isChat)
  );

  useChatSocketHandlers({ chatUserList, setChattedUserList, setUnreadCount });

  const DirectMessage = [
    {
      option: "Direct Message",
      subOption: chattedUserList,
      route: "/message",
      name: "message",
    },
  ];

  return (
    <Fragment>
      {DirectMessage[0]?.subOption?.length > 0 && (
        <List className="min-w-[0px] max-h-[250px] overflow-y-auto sidebar-scroller">
          {DirectMessage[0]?.subOption?.map((item, index) => (
            <NavLink
              key={item?.id}
              to={`/message/${item?.id}`}
              exact={`${true}`}
              state={{
                new: true,
                user: {
                  ...item,
                  userId: item?.id,
                  userName: item?.username,
                },
              }}
              className={` scrollbar-hide rounded-md ${
                pathname === `/message/${item?.id}`
                  ? "bg-primary focus:font-semibold"
                  : ""
              }`}
            >
              <ListItem className="flex gap-3 p-2 text-debatePalette-buttonBorder font-medium capitalize hover:bg-primary focus:bg-primary ">
                <MessageSidebarCard
                  item={item}
                  pathname={pathname}
                  key={item?.id}
                />
              </ListItem>
            </NavLink>
          ))}
        </List>
      )}
    </Fragment>
  );
};

export default DirectMessageNav;
