import {
  Card,
  CardBody,
  List,
  ListItem,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import WorkSpaceOptionCard from "../UI/Cards/WorkSpaceOptionCard";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useNavigate } from "react-router-dom";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useAuth } from "../../Context/AuthContext";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import toast from "react-hot-toast";

const WorkspaceOptionMenu = ({ email, filteredWorkspaceList, isLoading }) => {
  const { user } = useAuth();
  const { selectWorkspace } = useWorkspace();
  const { selectSubWorkspace, selectWorkspaceId } = useSubWorkspace();
  const [loading, setLoading] = React.useState(null);

  const navigate = useNavigate();
  const handleWorkspaceSelection = async (item) => {
    setLoading(item.workSpaceId);
    localStorage.removeItem("newWorkspace");
    selectWorkspace(item);

    const subworkspaceList = await getSubWorkspaceByWorkId(
      item.workSpaceId,
      user?.userId
    );

    const subworkspace = subworkspaceList?.listOfSubworkspaces[0];
    selectWorkspaceId(item.workSpaceId);
    selectSubWorkspace(subworkspace);
    if(!subworkspace){
      toast.error("No subworkspace found for this workspace");
      setLoading(false);
      return;
    }
    setLoading(null);
    navigate("/feed-public", { replace: true });
  };

  return (
    <div className="bg-debatePalette-background rounded-md !border-debatePalette-shadow !shadow-debatePalette-shadow shadow-shadowDebate">
      <Card className="rounded-md p-0">
        <CardBody className="p-0">
          <Typography className="my-2 px-4 text-md text-debatePalette-title w-full ">
            {`Workspace for `}{" "}
            <span className="text-md text-debatePalette-title font-semibold">
              {email}
            </span>
          </Typography>

          {isLoading && (
            <List className="p-0 h-auto max-h-36 overflow-auto gap-0 rounded-none">
              <div className="flex justify-between p-3 w-full animate__fadeIn">
                <div className="flex gap-4">
                  <div className="h-12 w-12 bg-gray-300 rounded-full"></div>
                  <div className="flex flex-col gap-2">
                    <div className="h-4 w-24 bg-gray-300 rounded"></div>
                    <div className="flex gap-2 items-center">
                      <div className="h-4 w-20 bg-gray-300 rounded"></div>
                      <div className="h-4 w-16 bg-gray-300 rounded"></div>
                    </div>
                  </div>
                </div>
                <div className="h-6 w-6 bg-gray-300 rounded"></div>
              </div>
              <div className="flex justify-between p-3 w-full animate__fadeIn">
                <div className="flex gap-4">
                  <div className="h-12 w-12 bg-gray-300 rounded-full"></div>
                  <div className="flex flex-col gap-2">
                    <div className="h-4 w-24 bg-gray-300 rounded"></div>
                    <div className="flex gap-2 items-center">
                      <div className="h-4 w-20 bg-gray-300 rounded"></div>
                      <div className="h-4 w-16 bg-gray-300 rounded"></div>
                    </div>
                  </div>
                </div>
                <div className="h-6 w-6 bg-gray-300 rounded"></div>
              </div>
            </List>
          )}

          {filteredWorkspaceList?.length > 0 && (
            <List className="p-0 h-auto max-h-36 overflow-auto gap-0 rounded-none">
              {filteredWorkspaceList?.map((item) => (
                <div
                  key={item.workSpaceName}
                  onClick={() => handleWorkspaceSelection(item)}
                >
                  <hr className="border-debatePalette-bodyText rounded-none" />
                  <ListItem className="p-0 rounded-none">
                    <WorkSpaceOptionCard
                      itemId={item?.workSpaceId}
                      member={item?.usersCount}
                      title={item?.workSpaceName}
                      picture={item?.profileImage}
                      loading={loading}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default WorkspaceOptionMenu;
