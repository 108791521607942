// Refactored AddEditRole Component
import React, { useEffect, useState } from "react";
import BasicButton from "../UI/Buttons/BasicButton";
import BasicSelect from "../UI/Select/BasicSelect";
import DatePicker from "../UI/DatePicker/DatePicker";
import {
  CardBody,
  Dialog,
  DialogHeader,
  DialogBody,
  Typography,
  Card,
} from "@material-tailwind/react";
import InputField from "../../Layout/InputField";
import toast from "react-hot-toast";
import { getUTCDate } from "../../Utils/Helper";
import {
  useAddSingleUserToSubWorkspace,
  useEditUserToSubWorkspace,
} from "../../Web-Hooks/Workspace/use-subworkspace";

const AddEditRole = ({
  open,
  onClose,
  profile,
  action,
  roleOptions,
  forumOptions,
  loggedInUser,
  isOpenToSelect = false,
  onSave,
}) => {
  const [localProfile, setLocalProfile] = useState(profile);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedForum, setSelectedForum] = useState(null);
  const {
    mutateAsync: addUserToSubWorkspace,
    isPending: addUserToSubWorkspacePending,
  } = useAddSingleUserToSubWorkspace();

  const {
    mutateAsync: editUserToSubworkspace,
    isPending: editUserToSubWorkspacePending,
  } = useEditUserToSubWorkspace();

  useEffect(() => {
    setLocalProfile(profile);
    setSelectedRole(roleOptions.find((opt) => opt.value === profile?.role));
    setSelectedForum(
      forumOptions.find((opt) => opt.value === profile?.subWorkSpaceId)
    );
  }, [profile, roleOptions, forumOptions]);

  const handleInputChange = (e) => {
    setLocalProfile({ ...localProfile, [e.target.name]: e.target.value });
  };

  const handleAddUser = async () => {
    if (
      localProfile?.email?.toLowerCase() === loggedInUser?.email?.toLowerCase()
    ) {
      toast.error(`You are already added please enter another email`);
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(localProfile?.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    const newUser = {
      LoginUserId: loggedInUser?.userId,
      Name: localProfile?.userName,
      Email: localProfile?.email,
      RoleName: selectedRole?.value,
      CreatedAt: getUTCDate(),
      SubWorkSpaceId: selectedForum?.value,
    };
    
    if (Object.values(newUser).some((value) => !value)) {
      toast.error(`Please fill all the required fields`);
      return;
    }

    addUserToSubWorkspace(newUser)
      .catch((err) => {
        console.log(err);
      })
      .finally(async () => {
        onSave();
        onClose();
      });
  };

  const handleEditUser = async () => {
    const updatedUser = {
      LoginUserId: loggedInUser?.userId,
      UserId: localProfile?.userId,
      RoleName: selectedRole?.value,
      CreatedAt: getUTCDate(),
      SubWorkSpaceId: selectedForum?.value,
    };
    editUserToSubworkspace(updatedUser)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        onSave();
        onClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        onSave();
      }}
      title={`${action === "add" ? "Add" : "Edit"} Role`}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className=" min-w-[0%] md:min-w-[0%] lg:min-w-[0%] 2xl:min-w-[0%] max-w-[80%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[25%] w-auto md:w-auto lg:w-auto 2xl:w-auto"
      size="xs"
      handler={() => {
        onClose();
        onSave();
      }}
    >
      <DialogHeader
        id="alert-dialog-title"
        className="w-full flex flex-col justify-center items-center gap-2 border-b border-debatePalette-buttonBorder"
      >
        <Typography className="text-2xl font-semibold text-debatePalette-title">
          Role Management
        </Typography>
      </DialogHeader>
      <DialogBody className="w-full bg-gray-100 rounded-lg">
        <Card
          className="border border-debatePalette-buttonBorder"
          shadow={false}
        >
          <CardBody className="py-0">
            <>
              <div className="flex border-b border-debatePalette-buttonBorder gap-2 items-center py-3">
                <label
                  htmlFor="name"
                  className="min-w-20 text-sm text-debatePalette-title font-semibold"
                >
                  Name
                </label>
                <InputField
                  inputName={"userName"}
                  className={"border-debatePalette-buttonBorder"}
                  onChange={handleInputChange}
                  disable={action === "edit"}
                  containerClassName={"!min-w-0"}
                  value={localProfile?.userName || ""}
                />
              </div>
              <div className="flex border-b border-debatePalette-buttonBorder gap-2 items-center py-3">
                <label
                  htmlFor="email"
                  className="min-w-20 text-sm text-debatePalette-title font-semibold"
                >
                  Email
                </label>
                <InputField
                  inputName={"email"}
                  className={"border-debatePalette-buttonBorder"}
                  containerClassName={"!min-w-0"}
                  onChange={handleInputChange}
                  disable={action === "edit"}
                  value={localProfile?.email || ""}
                />
              </div>
            </>
            <div className="flex border-b  border-debatePalette-buttonBorder gap-2 items-center py-3">
              <label
                htmlFor="forum"
                className="min-w-20 text-sm text-debatePalette-title font-semibold"
              >
                Forum
              </label>
              <BasicSelect
                Options={forumOptions}
                value={selectedForum}
                disabled={isOpenToSelect ? false : action === "edit"}
                onChange={(e) => setSelectedForum(e)}
                optionForDisable={"isAccept"}
              />
            </div>
            <div className="flex border-b  border-debatePalette-buttonBorder gap-2 items-center py-3">
              <label
                htmlFor="role"
                className="min-w-20 text-sm text-debatePalette-title font-semibold"
              >
                Role
              </label>
              <BasicSelect
                Options={roleOptions}
                value={selectedRole}
                onChange={(e) => setSelectedRole(e)}
              />
            </div>
            <div className="flex border-b border-debatePalette-buttonBorder gap-2 items-center py-3">
              <label
                htmlFor="assigned"
                className="min-w-20 text-sm text-debatePalette-title font-semibold"
              >
                Assigned
              </label>
              <DatePicker
                inputName={"assigned"}
                startDate={action === "add" ? new Date() : profile?.assigned}
                // handleChange={(date) => handleInputChange(date)}
                isDisable={true}
              />
            </div>
            <div className="flex justify-center  gap-2 items-center py-3">
              <BasicButton
                id={"add-edit-role-save"}
                loading={
                  addUserToSubWorkspacePending || editUserToSubWorkspacePending
                }
                color={"primary"}
                className={"px-5 py-1.5 capitalize"}
                onClick={() =>
                  action === "add" ? handleAddUser() : handleEditUser()
                }
              >
                {action === "add" ? "Add" : "Save"}
              </BasicButton>
            </div>
          </CardBody>
        </Card>
      </DialogBody>
    </Dialog>
  );
};

export default AddEditRole;
