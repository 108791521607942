import { ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import React from "react";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import BasicButton from "../Buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { useAddFollowing } from "../../../Web-Hooks/Profile/use-following";
import { useAuth } from "../../../Context/AuthContext";

const FollowCard = ({ user, type = "follower", filters }) => {
  const navigate = useNavigate();
  const { mutateAsync: handleFollow} = useAddFollowing();
  const { user: loggedInUser } = useAuth();
  const handleFollowing = (e, followingId) => {
    e.stopPropagation();
    const isFollowBack = type === "following" ? user?.isFollow : user?.isFollowBack;
    handleFollow({
      Type: !isFollowBack ? "follow" : "unfollow",
      FollowingUserId: followingId,
      FollowerUserId: loggedInUser?.userId,
      IsFollow: true,
      workspaceId: filters?.workspace?.value
    });
  };
  return (
    <ListItem
      className="flex justify-between border shadow-md hover:bg-inherit"
      onClick={() => navigate(`/profile/${user?.userId}`)}
      ripple={false}
    >
      <div className="flex gap-4 items-center">
        <ListItemPrefix>
          <ImageOrInitials
            imageSrc={user?.userImage}
            initials={user?.userName}
          />
        </ListItemPrefix>
        <div>
          <Typography variant="h6" color="blue-gray">
            {user?.userName}
          </Typography>
        </div>
      </div>
      <div className="text-end">
        {type === "follower" &&
          (!user?.isFollowBack ? (
            <BasicButton
              variant={"filled"}
              size={"sm"}
              className={
                "px-3 py-2 bg-primary text-debatePalette-title capitalize"
              }
              children={"Follow back"}
              onClick={(e)=>handleFollowing(e, user?.userId)}
            />
          ) : (
            <BasicButton
              variant={"outlined"}
              size={"sm"}
              className={"px-3 py-2 capitalize"}
              children={"Unfollow"}
              onClick={(e)=>handleFollowing(e, user?.userId)}
            />
          ))}
        {type === "following" && user?.isFollow && (
          <BasicButton
            variant={"outlined"}
            size={"sm"}
            className={"px-3 py-2 capitalize"}
            children={"Unfollow"}
            onClick={(e)=>handleFollowing(e, user?.userId)}
          />
        )}
      </div>
    </ListItem>

    // <div className="flex justify-between item-center">
    //   <div className="flex gap-5 items-center">
    //     <Avatar
    //       size="md"
    //       variant="circular"
    //       src={user?.profilePicture || "https://docs.material-tailwind.com/img/face-2.jpg"}
    //       alt="tania andrew"
    //       className="hidden md:block"
    //     />
    //     <Typography>{user?.name}</Typography>
    //   </div>
    //   <div className="">
    //     {
    //         user?.isNotFollowBack ? <BasicButton
    //         color={'primary'}
    //         size={"sm"}
    //         className={"px-3 py-2 "}
    //         children={"Follow back"}
    //         onClick={() => {}} /> : <Typography>Following</Typography>
    //     }
    //   </div>
    // </div>
  );
};

export default FollowCard;
