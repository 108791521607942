import { SEARCH_DEBATES, SEARCH_USERS } from "../apiRoutes";
import { getDataINSTANCE, getDataJSON_INSTANCE } from "../controller";

export const getSearchedUserList = async (userId, search,pageNo=1, pageSize=20) => {
  try {
    const data = await getDataINSTANCE(`${SEARCH_USERS}?userId=${userId}&keyword=${search}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to search users list");
    return data;
  } catch (error) {
    throw new Error("Failed to search users list");
  }
};
export const getSearchedDebateList = async (userId, workspaceId, search, pageNo=1, pageSize=10) => {
  try {
    const data = await getDataJSON_INSTANCE(`${SEARCH_DEBATES}?userId=${userId}&workspaceId=${workspaceId}&keyword=${search}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to search debates list");
    return data;
  } catch (error) {
    throw new Error("Failed to search debates list");
  }
};

// debatedLists