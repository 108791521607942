import React from "react";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";

const ProfileMenu = ({ openProfile, profileDetail }) => {
  return (
    <div onClick={openProfile} className="p-0 cursor-pointer">
      <ImageOrInitials
        imageSrc={profileDetail?.userImage ? profileDetail?.userImage : null}
        initials={
          profileDetail?.userName
            ? profileDetail?.userName
            : profileDetail?.email
        }
        size="h-12 w-12"
      />
    </div>
  );
};

export default ProfileMenu;
