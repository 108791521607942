import React, { useEffect, useState } from "react";
import DropdownSelect from "./DropdownSelect";
import TimePicker from "./TimePicker";
import DateSelector from "./DateSelector";
import SummaryDisplay from "./SummaryDisplay";
import {
  useGetNotificationSchedularSettingsByUserId,
  useResetSchedularSetting,
  useUpdateSchedularSetting,
} from "../../Web-Hooks/Notification/use-notification";
import { useAuth } from "../../Context/AuthContext";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { Button } from "@material-tailwind/react";
import moment from "moment";
import toast from "react-hot-toast";

const NotificationScheduler = ({ handleClose }) => {
  const [recurrence, setRecurrence] = useState("Daily");
  const [startTime, setStartTime] = useState("09:00 AM");
  const [endTime, setEndTime] = useState("21:00 PM");
  const [selectedDays, setSelectedDays] = useState([]);
  const [monthlyDate, setMonthlyDate] = useState(new Date());
  const { user: loggedInUser } = useAuth();

  const { data, isLoading: isInitialDataLoading } =
    useGetNotificationSchedularSettingsByUserId(loggedInUser?.userId);
  const { mutateAsync: updateNotificationSchedule, isPending: isUpdating } =
    useUpdateSchedularSetting();
  const { mutateAsync: resetNotificationSchedule, isPending: isResetting } =
    useResetSchedularSetting();

  useEffect(() => {
    if (data?.message === "Data not available") {
      return;
    }

    const handleUpdateData = (data) => {
      setRecurrence(data?.recurrenceDay || "Daily");
      setStartTime(data?.startTime || "09:00 AM");
      setEndTime(data?.endTime || "06:00 PM");
      setSelectedDays(data?.day || []);
      setMonthlyDate(data?.date || new Date());
    };

    handleUpdateData(data);
  }, [data]);

  const recurrenceOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Every 3 Days", label: "Every 3 Days" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Every 15 Days", label: "Every 15 Days" },
  ];

  const handleRecurrenceChange = (option) => {
    setRecurrence(option.value);
  };

  const handleUpdateNotificationSchedule = async () => {
    if (!recurrence) {
      toast.error("Please select a recurrence option.");
      return;
    }

    if (recurrence === "Weekly" && selectedDays.length === 0) {
      toast.error("Please select at least one day for weekly notifications.");
      return;
    }

    if (recurrence === "Monthly" && !monthlyDate) {
      toast.error("Please select a date for monthly notifications.");
      return;
    }
    console.log(endTime)
    const UpdatedSchedularSettings = {
      userId: loggedInUser?.userId,
      startTime: moment(startTime, "HH:mm A").format("hh:mm A"),
      endTime: moment(endTime, "HH:mm A").format("hh:mm A"),
      recurrenceDay: recurrence,
      day: recurrence === "Weekly" ? selectedDays : null,
      date:
        recurrence === "Monthly"
          ? moment(monthlyDate).format("YYYY-MM-DD")
          : null,
    };

    try {
      await updateNotificationSchedule(UpdatedSchedularSettings);
      toast.success("Notification schedule updated successfully!");
    } catch (error) {
      toast.error("Failed to update notification schedule.");
      console.error("Error updating notification schedule:", error);
    }
  };
  const handleResetNotificationSchedule = async () => {
    try {
      await resetNotificationSchedule();
      setRecurrence("Daily");
      setStartTime("09:00 AM");
      setEndTime("06:00 PM");
      setSelectedDays([]);
      setMonthlyDate(new Date());
      toast.success("Notification schedule reset successfully!");
    } catch (error) {
      toast.error("Failed to resetting notification schedule.");
      console.error("Error resetting notification schedule:", error);
    }
  };

  if (isInitialDataLoading) {
    return (
      <div className="space-y-4">
        <LoadingSpinner color="#153C58" height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="space-y-4">
        <DropdownSelect
          label="Recurrence Pattern"
          options={recurrenceOptions}
          onChange={handleRecurrenceChange}
          value={recurrence}
        />
        <TimePicker
          startTime={
            startTime ? moment(startTime, "HH:mm A").format("HH:mm") : ""
          }
          endTime={endTime ? moment(endTime, "HH:mm A").format("HH:mm") : ""}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
        {recurrence === "Weekly" && (
          <DropdownSelect
            label="Select Days"
            options={[
              { value: "Monday", label: "Monday" },
              { value: "Tuesday", label: "Tuesday" },
              { value: "Wednesday", label: "Wednesday" },
              { value: "Thursday", label: "Thursday" },
              { value: "Friday", label: "Friday" },
              { value: "Saturday", label: "Saturday" },
              { value: "Sunday", label: "Sunday" },
            ]}
            value={selectedDays}
            isMulti
            onChange={(options) =>
              setSelectedDays(options.map((opt) => opt.value))
            }
          />
        )}
        {recurrence === "Monthly" && (
          <DateSelector value={monthlyDate} setValue={setMonthlyDate} />
        )}
        <SummaryDisplay
          recurrence={recurrence}
          startTime={startTime}
          endTime={endTime}
          selectedDays={selectedDays}
          monthlyDate={monthlyDate}
        />
      </div>
      <div className="flex justify-center gap-3">
        <Button
          variant="filled"
          className="bg-secondary text-debatePalette-background text-base px-6 py-1.5 normal-case font-normal cursor-pointer"
          onClick={handleResetNotificationSchedule}
          loading={isResetting}
        >
          Reset
        </Button>
        <Button
          variant="filled"
          className="bg-primary text-debatePalette-title text-base px-6 py-1.5 normal-case font-normal cursor-pointer"
          onClick={handleUpdateNotificationSchedule}
          loading={isUpdating}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          className=" text-debatePalette-title text-base px-6 py-1.5 normal-case font-normal cursor-pointer"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default NotificationScheduler;
