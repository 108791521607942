import { BellIcon } from "@heroicons/react/24/outline";
import { Badge, Button, Tooltip } from "@material-tailwind/react";
import React from "react";
import { useGetNotificationByUserId } from "../../Web-Hooks/Notification/use-notification";

const NotificationButton = ({ loggedInUser, navigate }) => {
  const { data } = useGetNotificationByUserId(loggedInUser?.userId);

  const notificationCount =
    data?.pages?.[data?.pages?.length - 1]?.notificationCount;

  return (
    <Badge content={notificationCount} invisible={notificationCount === 0}>
      <Button
        variant="text"
        ripple={false}
        className="border-none p-2"
        onClick={() => navigate("/notification")}
      >
        <Tooltip
          content="Notification"
          className="bg-white text-black border border-black"
        >
          <BellIcon className="h-8 w-8 text-debatePalette-background" />
        </Tooltip>
      </Button>
    </Badge>
  );
};

export default NotificationButton;
