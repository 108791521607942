import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getAllWorkspaceListNotificationStatus,
  getNominationByUserAndSubID,
  getNotificationByUser,
  getNotificationSchedularSetting,
  resetNotificationSchedularSetting,
  updateAcceptDeclineNominationStatus,
  updateNotificationSchedularSetting,
  updateNotificationStatus,
  updateWorkspaceNotificationStatus,
} from "../../api/notification/notification";
import { useAuth } from "../../Context/AuthContext";
import { updateDynamicNotificationView } from "../../Utils/Helper";

export const useGetNotificationByUserId = (userId) =>
  useInfiniteQuery({
    queryKey: ["notification", userId],
    queryFn: ({ pageParam = 1 }) => getNotificationByUser(userId, pageParam),
    getNextPageParam: (lastPage) => {
      const notification = lastPage;
      return notification?.currentPage < notification?.totalPages
        ? notification?.currentPage + 1
        : undefined;
    },
    initialPageParam: 1,
    select: (data) => data,
    enabled: !!userId,
    gcTime: 1000,
  });
export const useGetNotificationSchedularSettingsByUserId = (userId) =>
  useQuery({
    queryKey: ["notification", "schedular", userId],
    queryFn: () => getNotificationSchedularSetting(userId),
    select: (data) => data,
    enabled: !!userId,
  });

export const useGetNominationByUserIdAndSubId = (userId, type) =>
  useInfiniteQuery({
    queryKey: ["nomination", userId, type],
    queryFn: ({ pageParam = 1 }) =>
      getNominationByUserAndSubID(userId, type, pageParam),
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!type,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

export const useGetNotificationStatusWorkspaceWise = (userId) =>
  useQuery({
    queryKey: ["workspace-status", userId],
    queryFn: () => getAllWorkspaceListNotificationStatus(userId),
    select: (data) => data.data,
    enabled: !!userId,
  });

export const useResetSchedularSetting = () => {
  const queryClient = useQueryClient();
  const { user: loggedInUser } = useAuth();
  return useMutation({
    mutationFn: async (userId = loggedInUser?.userId) => {
      return await resetNotificationSchedularSetting(userId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["notification", "schedular", loggedInUser?.userId],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useUpdateSchedularSetting = () => {
  const queryClient = useQueryClient();
  const { user: loggedInUser } = useAuth();
  return useMutation({
    mutationFn: async (updatedSettings) => {
      return await updateNotificationSchedularSetting(updatedSettings);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["notification", "schedular", loggedInUser?.userId],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useUpdateNominationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (nominationObj) => {
      return await updateAcceptDeclineNominationStatus(nominationObj);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["nomination"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useUpdateNotificationStatus = () => {
  const { user: loggedInUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (statusObj) => {
      const data = await updateNotificationStatus(statusObj);
      return {
        ...data,
        userId: loggedInUser?.userId,
      };
    },
    onSuccess: async (data) => {
      const { message, userId } = data;
      const { notificationId } = message;
      await queryClient.setQueryData(["notification", userId], (oldData) =>
        updateDynamicNotificationView(oldData, notificationId)
      );
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useUpdateNotificationList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (workspaceStatusObj) => {
      return await updateWorkspaceNotificationStatus(workspaceStatusObj);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["workspace-status"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["nomination"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
