import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import NoData from "../../Components/Functionality/NoData";
import FollowCard from "../../Components/UI/Cards/FollowCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { useGetFollowingList } from "../../Web-Hooks/Profile/use-following";
import SearchFilter from "./SearchFilter";

const FollowingList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams("");
  const { selectedWorkspace, workspaceList } = useWorkspace();
  const { user } = useAuth();
  const deferredSearch = useDebounce(search?.get("search") || "", 1000);
  const [filter, setFilters] = useState(null);
  const {
    data,
    isLoading: isFollowingListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetFollowingList(
    user?.userId,
    filter ? filter.workspace.value : selectedWorkspace?.workSpaceId,
    deferredSearch,
    search.get("selected") === "followings"
  );

  const followingList = data?.pages?.flatMap((page) => page?.followingLists);

  const workspaceOptions =
    workspaceList?.map((workspace) => {
      return {
        label: workspace.workSpaceName,
        value: workspace.workSpaceId,
      };
    }) || [];
  return (
    <div className="h-full flex w-[70%] flex-col gap-5">
      <div className="h-auto flex gap-4 justify-between w-full">
        {" "}
        <SearchFilter
          search={search?.get("search") || ""}
          setSearch={(value) =>
            setSearch({
              selected: search.get("selected"),
              search: value,
            })
          }
          showFilters={["search", "workspace"]}
          setFilters={setFilters}
          filters={
            filter || {
              workspace: {
                label: selectedWorkspace?.workSpaceName,
                value: selectedWorkspace?.workSpaceId,
              },
            }
          }
          workspaceOptions={workspaceOptions}
        />
      </div>
      <div className="flex w-full h-[90%] flex-col gap-2 overflow-y-auto">
        {isFollowingListLoading && RenderLoaderSkeleton(4, "motion")}
        {followingList?.length === 0 && !isFollowingListLoading && (
          <NoData
            isNoData={true}
            message={"No followings yet. Click below to get started!"}
            size={"w-full h-[80%]"}
          >
            <NoData.Buttons
              btn={"Search People"}
              handleClick={() => navigate("/search")}
            />
          </NoData>
        )}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isFollowingListLoading}
        >
          {followingList?.map((active) => (
            <FollowCard user={active} key={active.userId} type={"following"} filters={filter} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FollowingList;
