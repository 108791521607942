import React from "react";
import SubscriptionPlans from "./SubscriptionPlan";

const UpgradePlan = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <SubscriptionPlans />
    </div>
  );
};

export default UpgradePlan;
