import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Avatar } from "@material-tailwind/react";
import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { getInitialsFromEmail } from "../../../Utils/Helper";
import WorkspaceStack from "../AvatarStack/WorkSpaceStack";

const WorkSpaceOptionCard = ({ picture, title, member, loading, itemId }) => {
  return (
    <div className="flex justify-between p-3 w-full ">
      <div className="flex gap-4">
        {picture ? (
          <Avatar src={picture} alt={title} className="flex-shrink-0" />
        ) : (
          <div className="inline-flex items-center flex-shrink-0 justify-center h-12 w-12 uppercase font-bold shadow-lg border border-primary-basic text-primary bg-white  rounded-full">
            {getInitialsFromEmail(title)}
          </div>
        )}
        <div>
          <p className="text-sm text-debatePalette-title max-h-[80%] font-medium xs:line-clamp-1 overflow-hidden">
            {title}
          </p>
          <div className="flex gap-2">
            <WorkspaceStack />
            <p>{`${member} members`}</p>
          </div>
        </div>
      </div>
      {loading === itemId ? (
        <ThreeDots height={20} width={20} color="#153C58"/>
      ) : (
        <ArrowRightIcon className="h-6 w-6" />
      )}
    </div>
  );
};

export default WorkSpaceOptionCard;
