import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../../Assets/Images/DB_AI.png";
import AIMotionValidator from "../../../Components/Functionality/AIMotionValidator";
import DebateTextArea from "../../../Components/UI/Inputs/DebateTextArea";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import useDebounce from "../../../Services/useDebounce";
import { useMotionAgent } from "../../../Web-Hooks/Agents/use-motion-agents";
import { usePlan } from "../../../Context/PlanContext";
import { VALIDATE_AND_SUGGEST_MOTION } from "../../../Utils/Constant";
import { handleAIUpgradePlan } from "../../../Services/SweetAlert";

const AddMotionWithAI = ({ setError, error }) => {
  const { debateInfo, handleStateUpdate } = useCreateDebateContext();
  const motion = useDebounce(debateInfo.MotionOrClaim, 1000);
  const [showAIValidator, setShowAIValidator] = useState(false);
  const [activeBlinkingButton, setActiveBlinkingButton] = useState();
  const { currentPlan } = usePlan();
  const { data: motionAssistance , isLoading: motionAssistanceLoading} = useMotionAgent(motion, currentPlan?.aiFeature);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const from = query?.get("from");

  const wordLimit = 150;

  // const handleChange = (value) => {
  //   // Split the text by spaces to count the words
  //   const words = value.split(/\s+/).filter((word) => word.length > 0); // Filter out any empty words

  //   // Check if the word count exceeds 500
  //   if (words.length >= maxLength) {
  //     // Truncate the value to the first 500 words
  //     const truncateValue = words.slice(0, maxLength).join(" ");
  //     handleStateUpdate({
  //       ...debateInfo,
  //       MotionOrClaim: truncateValue,
  //     });
  //     return;
  //   } else {
  //     // If it's within the limit, just update the state as usual
  //     handleStateUpdate({
  //       ...debateInfo,
  //       MotionOrClaim: value,
  //     });
  //   }
  // };

  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
      handleStateUpdate({
        ...debateInfo,
        MotionOrClaim: inputText,
      });
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      handleStateUpdate({
        ...debateInfo,
        MotionOrClaim: limitedWords,
      });
    }
  };

  const handleMotionValidator = () => {
    if (!currentPlan?.aiFeature) {
      handleAIUpgradePlan(VALIDATE_AND_SUGGEST_MOTION, () =>
        navigate(`/upgrade-plan`)
      );
      return;
    }
    setShowAIValidator(true);
  };

  return (
    <div className="flex flex-row w-full">
      <AIMotionValidator
        open={showAIValidator}
        debateInfo={debateInfo}
        handleClose={() => setShowAIValidator(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => setActiveBlinkingButton(false)}
        setActiveBlinkingButton={setActiveBlinkingButton}
        motionAssistance={motionAssistance}
        motionAssistanceLoading={motionAssistanceLoading}
        // citationSuggester={citationSuggester}
      />

      <div
        className="flex flex-row gap-2 w-full"
        onMouseEnter={() => setActiveBlinkingButton(true)}
        onMouseLeave={() => setActiveBlinkingButton(false)}
      >
        <DebateTextArea
          id="motion"
          readOnly={from === "Redebate" || from === "pending"}
          value={debateInfo?.MotionOrClaim}
          handleChange={(e) => {
            handleContentChange(e.target.value);

            e.target.value !== "" && setError({ ...error, MotionOrClaim: "" });
          }}
          // onFocus={() => handleBlinkButtonClick("Motion")}
          // onBlur={() => handleBlinkButtonClick("Motion")}
          // handleOnClick={() => setActiveBlinkingButton((prevState) => ({
          //   ...prevState, // Spread the previous state to keep other fields intact
          //   Motion: true, // Update only the Category field
          // }))}
          label={
            <div className="flex flex-row justify-between">
              <p className="w-full">
                Write the motion or claim you want to debate!
              </p>
              {from !== "Redebate" &&
                from !== "pending" &&
                debateInfo?.MotionOrClaim && (
                  <button
                    id="motionValidator"
                    onClick={handleMotionValidator}
                    className={`text-white ${
                      activeBlinkingButton ? "animate-blinker" : ""
                    } `}
                  >
                    <img
                      src={Logo}
                      alt="Logo"
                      height="30"
                      width="30"
                      className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
                    />
                  </button>
                )}
            </div>
          }
          maxLength={150}
          placeholder={"e.g. election_2024"}
        />
        <div className="mt-10">
          {motionAssistance?.output?.Debate_motion && (
            <CheckCircleIcon color="green" className="h-7 w-7  " />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMotionWithAI;
