import { Avatar } from "@material-tailwind/react";
import React from "react";
import { getInitials } from "../../Utils/Helper";

const ImageOrInitials = ({
  imageSrc,
  initials,
  size = "h-12 w-12",
  classNameInitials,
  classNameImage,
  handleClick,
  showUserStatus = false,
  userStatus,
}) => {
  const isImageLink = imageSrc
    ? /^https?:\/\//i.test(imageSrc) || /^data:image\//i.test(imageSrc)
    : false;
  const minStylesArr = size.split(" ");
  const minStyles = `min-${minStylesArr[0]} min-${minStylesArr[1]} flex-shrink-0`;

  return (
    <div className={minStyles} onClick={handleClick}>
      {!isImageLink ? (
        <div
          className={`${size} relative inline-flex items-center flex-shrink-0 justify-center uppercase font-bold hover:z-10 focus:z-10 border border-primary text-primary bg-white rounded-full ${classNameInitials}`}
        >
          {initials ? getInitials(initials) : ""}
          {showUserStatus && (
            <span
              className={`absolute bottom-0 right-0 block h-[10px] w-[10px] rounded-full ${
                userStatus ? "bg-green-500" : "bg-red-500"
              }`}
            />
          )}
        </div>
      ) : (
        <div className="relative">
          <Avatar
            src={imageSrc}
            className={`${classNameImage} ${size} flex-shrink-0 rounded-full hover:z-10 focus:z-10 bg-white border border-primary text-primary`}
            alt={getInitials(initials)}
          />
          {showUserStatus && (
            <span
              className={`absolute bottom-0 right-0 block h-[10px] w-[10px] rounded-full ${
                userStatus ? "bg-green-500" : "bg-red-500"
              }`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageOrInitials;

