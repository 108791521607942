import { Switch, Typography } from "@material-tailwind/react";
import React from "react";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useAuth } from "../../Context/AuthContext";
import { useUpdateNotificationList } from "../../Web-Hooks/Notification/use-notification";
import toast from "react-hot-toast";

const ForumWiseNotification = ({ type, settingInfo, defaultSettings }) => {
  const { user } = useAuth();
  const { mutateAsync: updateStatus, isPending: isUpdating } =
    useUpdateNotificationList();

  const handleChangeNotification = async (type, workspace, subId) => {
    if (defaultSettings?.pauseAll) {
      toast.error("Please unpause all notification to change the settings");
      return;
    }
    if (!subId) {
      const updateSetting = {
        UserId: user.userId,
        DebateBaseLikeVote: [
          {
            DebatebaseId: workspace.debatebaseId,
            IsEnable: !workspace.isEnable,
            SubworkspaceId: workspace?.subworkspace?.map((sub) => sub.id),
          },
        ],
      };
      await updateStatus(updateSetting);
      return;
    }

    const isAlreadyEnabled = workspace?.subworkspace?.find(
      (sub) => sub.id === subId && sub.isEnable
    );

    const updateSetting = {
      UserId: user.userId,
      DebateBaseLikeVote: [
        {
          DebatebaseId: workspace.debatebaseId,
          IsEnable: !isAlreadyEnabled,
          SubworkspaceId: [subId],
        },
      ],
    };
    await updateStatus(updateSetting);
  };
  return (
    <>
      <Typography
        variant="h6"
        className="text-debatePalette-title font-semibold py-3"
      >
        {type === "Draft" ? "Debatebase Draft" : "Debatebase Like and Vote"}
      </Typography>
      <hr className="border-gray-300" />
      <form className="flex flex-col gap-4 overflow-y-auto py-3">
        {settingInfo?.map((workspace) => (
          <div className="flex flex-col gap-4" key={workspace?.debatebaseId}>
            <div className="flex w-full items-center justify-between">
              <Tooltip
                position="bottom-start"
                content={workspace?.debatebaseName}
                containerClass="w-full"
              >
                <label
                  htmlFor={workspace?.debatebaseId}
                  color="blue-gray"
                  className="font-medium line-clamp-1 overflow-x-hidden"
                >
                  {workspace?.debatebaseName}
                </label>
              </Tooltip>
              <Switch
                id={workspace?.debatebaseId}
                name={workspace?.debatebaseId}
                color="blue"
                disabled={isUpdating}
                checked={workspace?.isEnable}
                onChange={() => handleChangeNotification("LikeVote", workspace)}
                containerProps={{
                  className: "flex-shrink-0 p-0 w-16",
                }}
              />
            </div>
            {workspace?.subworkspace?.map((item) => (
              <div
                className="flex w-full items-center justify-between pl-5"
                key={item?.id}
              >
                <Tooltip
                  position="top-start"
                  content={item?.subworkspaceName}
                  containerClass="w-full"
                >
                  <label
                    htmlFor={item?.id}
                    color="blue-gray"
                    className="font-medium line-clamp-1 overflow-x-hidden"
                  >
                    {item?.subworkspaceName}
                  </label>
                </Tooltip>
                <Switch
                  id={item?.id}
                  name={item?.id}
                  color="blue"
                  checked={item?.isEnable}
                  disabled={isUpdating}
                  onChange={() =>
                    handleChangeNotification("LikeVote", workspace, item?.id)
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </form>
    </>
  );
};

export default ForumWiseNotification;
