import { GET_DEBATED_USER_LIST, GET_PRIVATE_DEBATE, GET_PRIVATE_USER_LIST, GET_PUBLIC_DEBATED, GET_PUBLIC_FEED_DEBATE, GET_PUBLIC_VOTED, GET_SEMI_PUBLIC_DEBATED, GET_SEMI_PUBLIC_FEED_DEBATE, GET_SEMI_PUBLIC_VOTED, GET_VOTED_USER_LIST } from "../apiRoutes";
import { getDataDEBATE_INSTANCE } from "../controller";

export const getPublicDebateList = async (userId, workspaceId, pageNo = 1, pageSize = 50) => {
      const data  = await getDataDEBATE_INSTANCE(`${GET_PUBLIC_FEED_DEBATE}?userId=${userId}&workspaceId=${workspaceId}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch public debate list");
      return data;

  }

  export const getPublicUserWiseDebatedList = async (userId, workspaceId,opponentId = "", pageNo = 1, pageSize = 50) => {
      const data  = await getDataDEBATE_INSTANCE(`${GET_PUBLIC_DEBATED}?userId=${userId}&workspaceId=${workspaceId}&opponentId=${opponentId}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch public debated list");
      return data;

  }

  export const getSemiPublicUserWiseDebatedList = async (userId, subWorkspaceId,opponentId = "", pageNo = 1, pageSize = 50) => {
      const data  = await getDataDEBATE_INSTANCE(`${GET_SEMI_PUBLIC_DEBATED}?userId=${userId}&subWorkspaceId=${subWorkspaceId}&opponentId=${opponentId}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch semi public debated list");
      return data;
  }

  export const getPublicUserWiseVotedList = async (userId, workspaceId,opponentId = "", pageNo = 1, pageSize = 10) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_PUBLIC_VOTED}?userId=${userId}&workspaceId=${workspaceId}&opponentId=${opponentId}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch public voted list");
      return data;
  }

  export const getSemiPublicUserWiseVotedList = async (userId, subWorkspaceId,opponentId = "", pageNo = 1, pageSize = 10) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_SEMI_PUBLIC_VOTED}?userId=${userId}&subWorkspaceId=${subWorkspaceId}&opponentId=${opponentId}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch semi public voted list");
      return data;
  }
  export const getSemiPublicDebateList = async (userId, subWorkspaceId, pageNo = 1, pageSize = 50) => {
    const data = await getDataDEBATE_INSTANCE(
      `${GET_SEMI_PUBLIC_FEED_DEBATE}?userId=${userId}&subWorkspaceId=${subWorkspaceId}&pageNo=${pageNo}&pageSize=${pageSize}`,
      "Failed to fetch semi-public debate list"
    );
    return data;
  };
  
  export const getPrivateDebateList = async (userId, subWorkspaceId,opponentId = "",pageNo = 1, pageSize = 50) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_PRIVATE_DEBATE}?userId=${userId}&subWorkspaceId=${subWorkspaceId}&opponentId=${opponentId}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch private debate list");
      return data;
  }

  export const getDebatedUserList = async (userId, workspaceId) => {
       const  data  = await getDataDEBATE_INSTANCE(`${GET_DEBATED_USER_LIST}?userId=${userId}&workspaceId=${workspaceId}`,"Failed to fetch debated user list");
      return data;
  }

  export const getSemiDebatedUserList = async (userId, subWorkspaceId) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_DEBATED_USER_LIST}?userId=${userId}&subworkspaceId=${subWorkspaceId}`,"Failed to fetch debated user list");
      return data;
  }

  export const getVotedUserList = async (userId, workspaceId) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_VOTED_USER_LIST}?userId=${userId}&workspaceId=${workspaceId}`, "Failed to fetch voted user list");
      return data;
  }

  export const getSemiVotedUserList = async (userId, subWorkspaceId) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_VOTED_USER_LIST}?userId=${userId}&subworkspaceId=${subWorkspaceId}`,"Failed to fetch voted user list");
      return data;
  }

  export const getPrivateUserList = async (userId, subWorkspaceId) => {
      const  data  = await getDataDEBATE_INSTANCE(`${GET_PRIVATE_USER_LIST}?userId=${userId}&subworkspaceId=${subWorkspaceId}`, "Failed to fetch private user list");
      return data;
 
  }
