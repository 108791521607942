import axios from "axios";
import { ADD_USER_TO_GROUP, CLEAR_CHAT_GROUP, CREATE_GROUP_CHAT, GET_GROUP_CHAT, GET_USER_GROUP_LIST, LEAVE_GROUP, MAKE_MEMBER_ADMIN, REMOVE_USER_FROM_GROUP, UPDATE_GROUP } from "../chatApiRoutes";

const baseUrl = process.env.REACT_APP_CHAT_URL

export const createGroupChat = async (data) => {
    const response  = await axios.post(`${baseUrl}${CREATE_GROUP_CHAT}`, data);
    return response;
}
export const updateGroupChat = async (groupId, data) => {
    const response  = await axios.patch(`${baseUrl}${UPDATE_GROUP}/${groupId}`, data);
    return response;
}
export const addMember = async (groupId, users) => {
    const response  = await axios.post(`${baseUrl}${ADD_USER_TO_GROUP}/${groupId}`, users);
    return response;
}
export const clearChat = async (groupId, userId) => {
    const response  = await axios.post(`${baseUrl}${CLEAR_CHAT_GROUP}/${groupId}/${userId}`);
    return response;
}

export const removeMember = async (groupId, userId) => {
    const response  = await axios.delete(`${baseUrl}${REMOVE_USER_FROM_GROUP}/${groupId}/${userId}`);
    return response;
}
export const makeAdmin = async (groupId, data) => {
    const response  = await axios.post(`${baseUrl}${MAKE_MEMBER_ADMIN}/${groupId}/assign-admin`, data);
    return response;
}
export const leaveGroup = async (groupId, userId) => {
    const response  = await axios.delete(`${baseUrl}${LEAVE_GROUP}/${groupId}/${userId}`);
    return response;
}

export const getGroupList = async (userId, workId, subId) => {
    const data  = await axios.get(`${baseUrl}${GET_USER_GROUP_LIST}/${userId}/${workId}?subworkspaceId=${subId}`);
    return data;
}

export const getGroupHistory = async (groupId,userId, page, limit) => {
    const data  = await axios.get(`${baseUrl}${GET_GROUP_CHAT}/${groupId}/${userId}?page=${page || 1}&limit=${limit || 10}`, "Failed to fetch chat history");
    return data;
}