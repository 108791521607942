import React from "react";
import Select, {components} from "react-select";

const DropdownSelect = ({ label, options, onChange, value, isMulti = false }) => {
  return (
    <div>
      <label className="block text-lg font-semibold text-debatePalette-title mb-1">
        {label} <span className="text-debatePalette-timer">*</span>
      </label>
      <Select
        components={{
          Menu: (props) => <components.Menu className="!z-10"  {...props} />,
        }}
        options={options}
        onChange={onChange}
        value={isMulti ? options.filter((opt) => value.includes(opt.value)) : options.find((opt) => opt.value === value)}
        isMulti={isMulti}
        className="text-sm text-debatePalette-title"
      />
    </div>
  );
};

export default DropdownSelect;
