import React from "react";
import AISubscriptionPlan from "./AISubscriptionPlan";

const UpgradeAIPlan = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <AISubscriptionPlan />
    </div>
  );
};

export default UpgradeAIPlan;
