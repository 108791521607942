import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import NoData from "../../Components/Functionality/NoData";
import { NewsCard } from "../../Components/UI/Cards/NewsCard";
import Container from "../../Components/UI/Container";
import SearchField from "../../Components/UI/Inputs/SearchField";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import useDebounce from "../../Services/useDebounce";
import { useGetNewsList } from "../../Web-Hooks/News/use-news";

const News = () => {
  const [search, setSearch] = useState("");
  const queryClient = useQueryClient();
  const DeferredSearch = useDebounce(search, 500);

  const {
    data,
    isLoading: isNewsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetNewsList(DeferredSearch);

  useEffect(() => {
    return () => {
      queryClient.setQueryData(["news"], (data) => ({
        pages: data?.pages?.slice(0, 1),
        pageParams: data?.pageParams?.slice(0, 1),
      }));
    };
  }, [queryClient]);

  const newsLists = data?.pages?.flatMap((page) => page?.newsLists);
  // const filteredNewsLists = newsLists?.filter(
  //   (news) =>
  //     news.title.toLowerCase().includes(DeferredSearch.toLowerCase()) ||
  //     news.description.toLowerCase().includes(DeferredSearch.toLowerCase())
  // );

  return (
    <Container>
      {/* <NewsTabBar
          type="horizontal"
          className={"!overflow-x-scroll lg:!overflow-x-hidden"}
          selected={selected}
          setSelected={setSelected}
          NewsTabData={NewsTabData}
        /> */}
      {
        <div className="flex flex-col gap-2 w-full h-full">
          <div className=" w-full h-[10%] md:w-[60%] flex items-center gap-x-2">
            <SearchField
              id="search-news"
              name="search-news"
              search={search}
              setSearch={setSearch}
              placeholder="Search news"
            />
          </div>
          <div className="flex flex-col gap-2 h-[100%] w-full">
            <div className=" w-full h-full grid grid-cols-1 gap-3 place-items-center overflow-y-auto">
              <InfiniteScroll
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                isLoading={isNewsLoading}
              >
                {newsLists?.length === 0 && !isNewsLoading && (
                  <NoData
                    isNoData={true}
                    message={"No News Found"}
                    size={"w-full h-[80%]"}
                  />
                )}
                {isNewsLoading && (
                  <LoadingSpinner height={80} width={80} color={"#153C58"} />
                )}
                {newsLists?.map((news, i) => (
                  <NewsCard
                    news={news}
                    key={news?.newsId}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      }
      {/* {selectedNews && <NewsDetail selectedNews={selectedNews} />} */}
    </Container>
  );
};

export default News;
