import React from "react";
import { GaugeComponent } from "react-gauge-component";

const GaugeComp = ({ percentage = 0, totalVotes = 0, handleOpen }) => {
  return (
    <div className="flex flex-col items-center gap-2">
      <div className="w-full overflow-hidden relative flex flex-col items-center">
        {/* Gauge Chart */}
        <div
          className="relative w-full hover:cursor-pointer"
          onClick={handleOpen}
        >
          {" "}
          <GaugeComponent
            id="gauge-component4"
            minValue={-100}
            maxValue={100}
            className="!w-full !shrink-0 "
            arc={{
              // nbSubArcs: 6,
              colorArray: ["#EA4228", "#F58B19", "#F5CD19", "#5BE12C"],
              // width: 0.26,
              // padding: 0.002,
              // cornerRadius: 1,
              subArcs: [
                {
                  limit: -50,
                  showTick: false,
                },
                {
                  limit: -10,
                  showTick: false,
                },
                {
                  limit: 10,
                  showTick: false,
                },

                {
                  limit: 50,
                  showTick: false,
                },
                { limit: 100, showTick: false },
              ],
            }}
            value={percentage}
            // pointer={{
            //   color: "#000000",
            //   length: 0.65,
            //   width: 10,
            //   elastic: true,
            //   type: "needle",
            // }}
            labels={{
              valueLabel: {
                matchColorWithArc: true,
                style: {
                  textShadow: "none",
                  fontWeight: 600,
                },
                formatTextValue: (val) => `${Math.abs(val)}%`,
              },
              tickLabels: {
                hideMinMax: true,
                // type: "outer",
                // defaultTickValueConfig: {
                //   formatTextValue: (val) => (val > 0 ? -val : Math.abs(val)),
                //   style: {
                //     fill: "#000000",
                //     fontSize: 14,
                //   },
                // },
                // defaultTickLineConfig: {
                //   hide: true,
                // },
                // ticks: [
                //   {
                //     value: -100,
                //     valueConfig: {
                //       formatTextValue: (val) => "Agree",
                //       style:{
                //         fill: "#000000",
                //         fontWeight: 500,
                //         fontSize: 10
                //       }
                //     },
                //   },
                //   { value: 0 },
                //   { value: 100,
                //     valueConfig: {
                //       formatTextValue: (val) => "Disagree",
                //       style:{
                //         fill: "#000000",
                //         fontWeight: 500,
                //         fontSize: 10
                //       }
                //     },
                //    },
                // ],
              },
            }}
          />
        </div>

        {/* Bottom Labels */}
        <div className="absolute bottom-0 flex justify-around font-semibold w-full text-xs">
          <span>Disagree</span>
          <span>Agree</span>
        </div>

        {/* Total Votes */}
      </div>
      <div className="font-semibold text-xs px-2 py-1 rounded-md bg-[#E8F5FB] text-debatePalette-link">
        Total {totalVotes} Votes
      </div>
    </div>
  );
};

export default GaugeComp;
