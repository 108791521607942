import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import DebateCard from "../../Components/UI/Cards/DebateCard";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetSearchedDebateList } from "../../Web-Hooks/Search/use-search";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const SearchDebates = ({ query = "" }) => {
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const [citationPopup, setCitationPopup] = useState(false);
  const [debate, setDebate] = useState(null);
  const {
    data,
    isLoading: isDebateListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetSearchedDebateList(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId,
    encodeURIComponent(query)
  );
  const debateList = data?.pages?.flatMap((page) => page?.debatedLists);

  return (
    <div className="h-full ">
      <CitationTagListPopup
        debate={debate}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setDebate}
      />
      <div className="flex flex-col gap-3 h-full">
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isDebateListLoading}
        >
          <div className="flex flex-col gap-2 h-full">
            {isDebateListLoading ? RenderLoaderSkeleton(3) : null}
            {!debateList || debateList?.length !== 0 ? (
              debateList?.map((debate, i) => (
                <DebateCard
                  debate={debate}
                  key={debate?.debateId}
                  setCitationPopup={setCitationPopup}
                  setDebate={setDebate}
                />
              ))
            ) : (
              <NoData message={"No debates found"} size={"w-full h-[80%]"} />
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SearchDebates;
