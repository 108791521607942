import moment from "moment";
import React from "react";

const TimePicker = ({ startTime, endTime, setStartTime, setEndTime }) => {
  return (
    <div className="flex items-center space-x-4">
      <div>
        <label className="block text-lg font-semibold text-debatePalette-title mb-1">
          Start Time <span className="text-debatePalette-timer">*</span>
        </label>
        <input
          type="time"
          value={startTime}
          onChange={(e) =>
            setStartTime(moment(e.target.value, "HH:mm").format("HH:mm A"))
          }
          className="border rounded-md p-2 w-full !text-debatePalette-title"
          style={{ color: "black" }}
        />
      </div>
      <div>
        <label className="block text-lg font-semibold text-debatePalette-title mb-1">
          End Time <span className="text-debatePalette-timer">*</span>
        </label>
        <input
          type="time"
          value={endTime}
          onChange={(e) =>
            setEndTime(moment(e.target.value, "HH:mm").format("HH:mm A"))
          }
          className="border rounded-md p-2 w-full text-debatePalette-title"
          style={{ color: "black" }}
        />
      </div>
    </div>
  );
};

export default TimePicker;
