import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetSavedReferenceList } from "../../Web-Hooks/Reference/use-reference";
import ReferenceList from "../Reference/ReferenceList";

const SavedReferenceList = () => {
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { data, isLoading: isReferenceListLoading,     fetchNextPage,
    hasNextPage,
    isFetchingNextPage } =
    useGetSavedReferenceList(loggedInUser?.userId, selectedWorkspace?.workSpaceId, "");

    const referenceList = data?.pages?.flatMap((page) => page?.referencesList);

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-col gap-2 h-full overflow-y-auto">
        <ReferenceList
          user={loggedInUser}
          referenceList={referenceList}
          isReferenceLoading={isReferenceListLoading}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </div>
    </div>
  );
};

export default SavedReferenceList;
