import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createCustomer,
  createPaymentIntent,
  createSubscription,
  getProductDetails,
  getSubscriptionPlans,
  getUserSubscribedPlan,
} from "../../api/subscription/subscription";

export const useGetSubscriptionPlans = () => {
  return useQuery({
    queryKey: ["subscription", "list"],
    queryFn: async () => {
      return await getSubscriptionPlans();
    },
    select: (data) => {
      return data.data.data;
    },
  });
};
export const useGetSubscribedPlan = (userId) => {
  return useQuery({
    queryKey: ["subscription", userId],
    queryFn: async () => {
      return await getUserSubscribedPlan(userId);
    },
    select: (data) => data.data.data,
    enabled: !!userId,
  });
};
export const useGetProductDetails = (planId, planType) => {
  return useQuery({
    queryKey: ["product", planId, planType],
    queryFn: async () => {
      return await getProductDetails(planId, planType);
    },
    select: (data) => data.data,
    enabled: !!planId && !!planType,
  });
};

export const useCreateCustomer = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createCustomer(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  })
}

export const useCreatePaymentIntent = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createPaymentIntent(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  })
}


export const useCreateSubscription = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createSubscription(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  })
}
