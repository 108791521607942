import { Button } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import EditableBodyPopup from "../../../Components/Popup/EditableBodyPopup";
import WorkspaceStack from "../../../Components/UI/AvatarStack/WorkSpaceStack";
import { SocketEvents, useSocket } from "../../../Context/SocketContext";
import { getUserImageUrl } from "../../../Utils/Helper";
import GroupMemberPopup from "./GroupMemberPopup";

const UserView = ({ groupMembers, loggedInUser, groupDetails, setGroupDetails, isLoggedInUserAdmin }) => {
  const [members, setMembers] = useState([]);
  const { socket } = useSocket();
  const [memberViewPopup, setMemberViewPopup] = useState(false);

  useEffect(() => {
    setMembers(groupMembers);
  }, [groupMembers]);

  useEffect(() => {
    if (!socket) return;

    const handleUpdateUserStatus = ({ userId, online }) => {
      setMembers((prevMembers) =>
        prevMembers?.map((member) =>
          member?.id === userId ? { ...member, online } : member
        )
      );
    };

    socket.on(SocketEvents.UPDATE_USER_STATUS, handleUpdateUserStatus);
    return () => {
      socket.off(SocketEvents.UPDATE_USER_STATUS, handleUpdateUserStatus);
    };
  }, [socket, members]);

  const sortedGroupMembers = members
    ?.filter((member) => member.id !== loggedInUser?.userId)
    ?.map((member) => {
      return {
        ...member,
        subWorkSpaceInvitationId: member?.id,
        userImage: getUserImageUrl(member?.id, member?.avatar),
        isOnline: member?.online,
        userName: member?.username || member?.email,
      };
    })
    .sort((a, b) => a?.isOnline - b?.isOnline);

  const membersToRender = sortedGroupMembers?.slice(0, 4);

  const sortedGroupMembersForPopup = sortedGroupMembers?.sort((a, b) => {
    if (a.id === groupDetails?.created_by) return -1;
    if (b.id === groupDetails?.created_by) return 1;
    return b.isOnline - a.isOnline;
  });

  return (
    <Fragment>
      <EditableBodyPopup
        open={memberViewPopup}
        title={"View Group Members"}
        size={"sm"}
        handleClose={() => setMemberViewPopup(false)}
      >
        <GroupMemberPopup
          groupDetails={groupDetails}
          setGroupDetails={setGroupDetails}
          groupMembers={sortedGroupMembersForPopup}
          isLoggedInUserAdmin={isLoggedInUserAdmin}
        />
      </EditableBodyPopup>
      <Button
        className="flex items-center"
        variant="text"
        size="sm"
        onClick={() => setMemberViewPopup(true)}
      >
        <WorkspaceStack Member={membersToRender} size={"h-10 w-10"} />
      </Button>
    </Fragment>
  );
};

export default UserView;
