import { useInfiniteQuery } from "@tanstack/react-query";
import {
  getSearchedDebateList,
  getSearchedUserList,
} from "../../api/search/search";

export const useGetSearchedUserList = (userId, search) => {
  return useInfiniteQuery({
    queryKey: ["search-users", userId, search],
    queryFn: ({ pageParam = 1 }) =>
      getSearchedUserList(userId, search, pageParam),
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined; // Load next page if available
    },
    enabled: !!userId && !!search,
  });
};

export const useGetSearchedDebateList = (userId, workspaceId, search) => {
  return useInfiniteQuery({
    queryKey: ["search-debates", userId, workspaceId, search],
    queryFn: ({ pageParam = 1 }) =>
      getSearchedDebateList(userId, workspaceId, search, pageParam),
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined; // Load next page if available
    },
    enabled: !!userId && !!workspaceId,
  });
};
