import { useInfiniteQuery } from "@tanstack/react-query";
import { getNewsList } from "../../api/news/news";

export const useGetNewsList = (search) =>
  useInfiniteQuery({
    queryKey: ["news", search],
    queryFn: ({ pageParam = 1 }) => getNewsList(search,pageParam),
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
  });
