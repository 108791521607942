import {  useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getSessionHistory, postQuestion } from "../../api/chatbot/chatbot";
import { useAuth } from "../../Context/AuthContext";

// postQuestion
export const usePostQuestion = () => {
  const { user: loggedInUser } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (questionParam) => {
      return await postQuestion({
        question: questionParam?.question,
        user_id: loggedInUser?.userId,
        session_id: loggedInUser?.sessionId,
      });
    },
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ["chatbot-history"],
          });
        },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useGetSessionHistory = (userId, session,page,pageSize) => {
  const { user: loggedInUser } = useAuth();
    return useQuery({
      queryKey: ["chatbot-history",page,pageSize],
      queryFn: async () => {
        return await getSessionHistory({
          user_id: loggedInUser?.userId,
          session_id: loggedInUser?.sessionId,
          page,
          pageSize
        });
      },
      select: (data) => data,
      enabled:!!loggedInUser ,

    });
  };
// sessionHistory
