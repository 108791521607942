import {
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { ReactComponent as Block } from "../../Assets/Svg/Block.svg";
import { ReactComponent as Report } from "../../Assets/Svg/Report.svg";
import MenuListRender from "../../Components/UI/Menus/MenuListRender";
import { useAuth } from "../../Context/AuthContext";
import { useBlockUser } from "../../Web-Hooks/Profile/use-profile";
import { useWorkspace } from "../../Context/WorkspaceContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ThreeDotIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
  );
}

export function ThreeDotAction({
  user,
  setReportPopup,
  setUserId,
}) {
  const { user: loggedInUser } = useAuth();
  const [open, setOpen] = useState(false);
  const sameUser = user === loggedInUser?.userId;
  const { mutateAsync: blockUser } = useBlockUser();
  const { selectedWorkspace } = useWorkspace();
  const navigate = useNavigate();
  const PostThreeDotMenu = [
    {
      SVG: Report,
      item: "Report",
      isHRline: false,
      isRedText: true,
      value: "report",
      handleClick: (e) => handleClick(e, "report"),
      isLoggedInUser: user === loggedInUser?.userId,
    },
    {
      SVG: Block,
      item: "Block User",
      isHRline: false,
      value: "blockUser",
      handleClick: (e) => handleClick(e, "blockUser"),
      isLoggedInUser: user === loggedInUser?.userId,
    },
  ];

  const handleBlockUser = async () => {
    try {
      const blockBody = {
        BlockedUserId: user,
        // BlockFrom: "Admin",
        BlockFrom: "UserToUser",
        // BlockFrom: "DebateWise",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
      };

      if (Object.values(blockBody).some((value) => !value)) {
        toast.error(`Failed to block user, please try again later.`);
        return;
      }

      const res = await blockUser(blockBody);
      if (res) {
        toast.success("User blocked successfully");
        navigate("/feed-public");
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  const handleClick = (e, value) => {
    if (value === "shareProfile") {
      console.log("Share Profile");
    } else if (value === "report") {
      setUserId(user);
      setReportPopup(true);
    } else if (value === "blockUser") {
      Swal.fire({
        title: "Block",
        text: `Are you sure you want to block this user?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, block it!",
        customClass: {
          container: "!z-[99999]",
        },
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const result = await handleBlockUser(); // Call your async function
            return result; // Pass data to the `then` block of Swal
          } catch (error) {
            Swal.showValidationMessage(`Error: ${error.message}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    }
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Fragment>
      <Menu
        placement="bottom-end"
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        open={open}
        handler={setOpen}
      >
        <MenuHandler>
          <IconButton
            variant="text"
            className={`${sameUser ? "hidden" : "block"} `}
          >
            <ThreeDotIcon />
          </IconButton>
        </MenuHandler>
        <MenuList className="!z-40">
          {PostThreeDotMenu.map((option, i) => (
            <MenuListRender
              ref={option.value}
              option={option}
              key={option.value}
            />
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
}
