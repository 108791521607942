import "animate.css";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const amount = search.get("amount");
  const plan = search.get("plan");
  const handleClick = () => {
    navigate("/upgrade-plan");
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center bg-white p-8 rounded-lg shadow-lg">
        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-red-100 rounded-full animate__animated animate__shakeX">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Payment Failed
        </h2>
        <p className="text-gray-600 mb-6">
          Unfortunately, your payment of ${amount} for the {plan} plan could not
          be processed at this time. Please try again or contact support.
        </p>
        <button
          className="px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg shadow transition"
          onClick={handleClick}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default PaymentFailed;
