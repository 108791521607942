import React from "react";
import { Progress } from "@material-tailwind/react";

const formatStorage = (value) => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let index = 0;
  while (value >= 1024) {
    value /= 1024;
    index++;
  }
  return { value: value?.toFixed(2), unit: units[index] };
};

const StorageUsage = ({ used, total }) => {
  const percentageUsed = (used / total) * 100;
  const percentageRemaining = 100 - percentageUsed;

  const usedStorage = formatStorage(used);
  const totalStorage = formatStorage(total);
  const remainingStorage = formatStorage(total - used);

  // Dynamically set color based on usage percentage
  const getColor = () => {
    if (percentageUsed >= 90) return "red";
    if (percentageUsed >= 50) return "orange";
    return "green";
  };

  return (
    <div className="w-full p-4 bg-white border rounded-lg shadow-md">
      {/* Storage Labels */}
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm font-semibold text-gray-600">
          {usedStorage.value} {usedStorage.unit} of {totalStorage.value}{" "}
          {totalStorage.unit} used
        </span>
        <span className="text-sm font-semibold text-gray-600">
          {remainingStorage.value} {remainingStorage.unit} (
          {percentageRemaining.toFixed(1)}%) remaining
        </span>
      </div>

      {/* Progress Bar */}
      <Progress value={percentageUsed} color={getColor()} />
    </div>
  );
};

export default StorageUsage;
