import { useInfiniteQuery } from "@tanstack/react-query";
import { getFollowersList } from "../../api/profile/followers";

export const useGetFollowersList = (userId, workSpaceId, search, enabled) =>
  useInfiniteQuery({
    queryKey: ["followers", userId, workSpaceId, search],
    queryFn: ({ pageParam = 1 }) =>
      getFollowersList(userId, workSpaceId, search, pageParam),
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!workSpaceId && enabled,
  });
