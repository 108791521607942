import { ADD_MOTION, CATEGORIES_COUNT_LIST, FIND_MOTION_LIST, GET_MOTION_CITATIONS, LIKE_DISLIKE_MOTION } from "../apiRoutes";
import AXIOS_JSON_INSTANCE from "../axiosJsonInstance";
import { getDataJSON_INSTANCE } from "../controller";

export const AddMotion = async (Motion) => {
  try {
    const { data } = await AXIOS_JSON_INSTANCE.post(`${ADD_MOTION}`, Motion);
    return data;
  } catch (error) {
    throw new Error("Failed to add motion");
  }
};

export const GetMotionList = async (filter, pageNo= 1, pageSize = 10) => {
    const data = await getDataJSON_INSTANCE(
      `${FIND_MOTION_LIST}?loginUserId=${filter.loggedInUserId}&workspaceId=${filter.workspaceId}&subworkspaceId=${filter.subworkspaceId}&type=${filter.type}&search=${filter?.search || ""}&pageNo=${pageNo}&pageSize=${pageSize}`, "Failed to fetch motion list"
    );
    return data;
};
export const GetMotionCategoryListCount = async (workspaceId) => {
    const data = await getDataJSON_INSTANCE(
      `${CATEGORIES_COUNT_LIST}?workspaceId=${workspaceId}`,"Failed to fetch motion list"
    );
    return data;

};

export const updateMotionLikeDislike = async(motion) => {
    try {
        const { data } = await AXIOS_JSON_INSTANCE.post(`${LIKE_DISLIKE_MOTION}`, motion);
        return data;
      } catch (error) {
        throw new Error("Failed to fetch Sub Workspaces Users");
      }
}

export const getMotionCitationList = async(id,userId) => {
    const data = await getDataJSON_INSTANCE(`${GET_MOTION_CITATIONS}?motionId=${id}&userId=${userId}`,"Failed to fetch motion citations");
    return data;

}