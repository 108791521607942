import React, { useState } from "react";
// import { Typography } from "@material-tailwind/react"; // Reusable Typography
// import GlobalAIAgent01 from "./GlobalAIAgent01";
import ChatBot from "./ChatBot";

const AgentLayout = () => {
  const [isOpen, setIsOpen] = useState(false); // Track whether the SpeedDial is open or not

  const toggleSpeedDial = () => {
    setIsOpen((prev) => !prev); // Toggle the open state
  };

  return (
    <div className="relative">
      {/* SpeedDial Trigger Button */}
      <div
        className="fixed bottom-10 right-5 z-40 flex justify-center items-center bg-transparent cursor-pointer"
        onClick={toggleSpeedDial} // Open or close SpeedDial on click
      >
        <div className="rounded-full flex justify-center items-center transition-all duration-300">
          <img
            src={require("../../Assets/Images/DB_AI.png")} // Example image for the SpeedDial trigger
            alt="Logo"
            className="w-20 h-20"
          />
        </div>
      </div>

      {/* SpeedDial Content with Animation */}
      <div
        className={`fixed bottom-28 right-8 z-50 flex flex-col items-center space-y-2 transition-all duration-500 ease-in-out transform ${
          isOpen ? "opacity-100 scale-100" : "opacity-0 scale-75"
        }`}
      >
        {/* Global AI Agent Action */}
        {/* <div
          className="flex flex-col justify-center items-center rounded-full border hover:cursor-pointer h-16 w-16 transition-all duration-300 transform "
        >
          <Typography color="blue-gray" className="text-xs font-normal">
            <GlobalAIAgent01 />
          </Typography>
        </div> */}

        {/* Chatbot Action */}
        <div
          className="flex flex-col justify-center items-center rounded-full border hover:cursor-pointer h-16 w-16 transition-all duration-300 transform "
        >
          <ChatBot />
        </div>
      </div>
    </div>
  );
};

export default AgentLayout;
