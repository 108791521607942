import { List, ListItem } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import GroupSidebarCard from "../../Pages/Messaging/group/GroupSidebarCard";
import { useGroupChatList } from "../../Web-Hooks/Chat/use-group-chat";
import useGroupSocket from "../../Context/groupSocketHandle";

const GroupMessageNav = ({
  selectedWorkspace,
  selectedSubWorkspace,
  setGroupUnreadCount,
}) => {
  const { user: loggedInUser } = useAuth();
  const { pathname } = useLocation();
  const [groupChatListData, setGroupChatListData] = useState([]);
  const { data: groupChatList } = useGroupChatList(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId,
    selectedSubWorkspace?.subWorkSpaceId,
    true,
    (data) => {
      return {
        totalCount: data?.data?.data?.totalUnreadCount,
        groups: data?.data?.data?.groups,
      };
    }
  );

  useEffect(() => {
    setGroupChatListData(groupChatList?.groups || []);
  }, [groupChatList]);

  useEffect(() => {
    if (groupChatListData?.length > 0) {
      setGroupUnreadCount({
        message: 0,
        group:
          groupChatListData?.reduce(
            (total, group) => total + group?.unreadCount,
            0
          ) || 0,
      });
    }
  }, [groupChatListData, setGroupUnreadCount]);

  useGroupSocket({
    setGroupUnreadCount,
    groupChatListData,
    setGroupChatListData,
  });

  return (
    <Fragment>
      <List className="min-w-[0px] max-h-[250px] overflow-y-auto sidebar-scroller">
        {groupChatListData?.map((item, index) => (
          <NavLink
            key={item?.id}
            to={`/group-message/${item?.id}`}
            exact={`${true}`}
            state={{
              new: true,
              group: item,
            }}
            className={` scrollbar-hide rounded-md ${
              pathname === `/group-message/${item?.id}`
                ? "bg-primary focus:font-semibold"
                : ""
            }`}
          >
            <ListItem className="flex gap-3 p-2 text-debatePalette-buttonBorder font-medium capitalize hover:bg-primary focus:bg-primary ">
              <GroupSidebarCard
                item={item}
                pathname={pathname}
                key={item?.id}
              />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Fragment>
  );
};

export default GroupMessageNav;
