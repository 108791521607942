import React, { useEffect, useState } from "react";
import {
  useGetPrivateUsers,
  useGetSemiDebatedUsers,
  useGetSemiVotedUsers,
} from "../../Web-Hooks/Debate/use-users";
import AccordionNav from "./AccordionNav";

const SemiFeedBar = ({ selectedSubWorkspace, loggedInUser, pathname }) => {
  const [open, setOpen] = useState(0);
  const { data: recentSemiVotedUsers } = useGetSemiVotedUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );
  const { data: recentPrivateUser } = useGetPrivateUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );

  const { data: recentSemiDebatedUsers } = useGetSemiDebatedUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );

  const handleOpen = (update) => {
    setOpen(open === update ? 0 : update);
  };

  useEffect(() => {
    const path = pathname.slice(1).split("/")[0];
    if (path === "debated") {
      setOpen("debated");
    } else if (path === "voted") {
      setOpen("voted");
    } else if (path === "private") {
      setOpen("private");
    } else if (path === "message") {
      setOpen("message");
    } else if (path === "debated-public") {
      setOpen("public-debated");
    } else if (path === "voted-public") {
      setOpen("public-voted");
    } else {
      setOpen(0);
    }
  }, [pathname]);

  const FeedBarOptions = [
    {
      option: "Feed",
      subOption: [],
      route: "/feed",
      name: "feed",
      hasAccess: true,
    },
    {
      option: "Debated",
      subOption: recentSemiDebatedUsers,
      route: "/debated",
      name: "debated",
      hasAccess: true,
    },
    {
      option: "Voted",
      subOption: recentSemiVotedUsers,
      route: "/voted",
      name: "voted",
      hasAccess: true,
    },
    {
      option: "Private",
      subOption: recentPrivateUser,
      route: "/private",
      name: "private",
      hasAccess: true,
    },
  ];
  return (
    <div>
      {" "}
      {selectedSubWorkspace && FeedBarOptions.length > 0 ? (
        <div className="mx-4">
          <AccordionNav
            open={open}
            pathname={pathname}
            Options={FeedBarOptions}
            handleClose={() => {}}
            handleOpen={handleOpen}
            loggedInUser={loggedInUser}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SemiFeedBar;
