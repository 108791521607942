import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import UserAvtarUpload from "../../../Components/UI/AvatarStack/UserAvtarUpload";
import EditableInput from "../../../Components/UI/Inputs/EditableInput";
import EditableTextArea from "../../../Components/UI/Inputs/EditableTextArea";
import { useAuth } from "../../../Context/AuthContext";
import { useSocket } from "../../../Context/SocketContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { getUserImageUrl } from "../../../Utils/Helper";
import {
  useLeaveGroup,
  useUpdateGroup,
} from "../../../Web-Hooks/Chat/use-group-chat";
import {
  useDeleteBlob,
  useUploadCitation,
} from "../../../Web-Hooks/LibraryAPI/use-citation";

const GroupDetailsPopup = ({ groupDetails, setGroupDetails, groupMembers, isLoggedInUserAdmin, handleClose }) => {
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user: loggedInUser } = useAuth();
  const { sendGroupMessage } = useSocket();
  const navigate = useNavigate();
  const [groupImage, setGroupImage] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [group, setGroup] = useState(groupDetails);
  const [leavePopup, setLeavePopup] = useState(false);
  const { mutateAsync: updateGroup, isPending: isUpdating } = useUpdateGroup();
  const { mutateAsync: leaveGroup, isPending: isLeaving } = useLeaveGroup();
  const { mutateAsync: uploadCitationOnServer, isPending: isUploading } =
    useUploadCitation();
  const { mutateAsync: deleteBlob } = useDeleteBlob();

  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";

  useEffect(() => {
    setGroupImage(group?.groupAvatar);
    setImageFile(null);
  }, [group]);

  const handleLeaveGroup = async () => {
    try {
      const response = await leaveGroup({ id: group.id, userId: loggedInUser?.userId });
      if(!response.data.success){
        toast.error(`${response.data.error.message}, please assign admin role to another member and try again`);
        return;
      }
      const Message = {
        type: "user_left",
        content: {
          userId: loggedInUser?.userId,
          userName: loggedInUser?.userName,
        },
      };
      sendGroupMessage(
        groupDetails?.id,
        loggedInUser?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
      toast.success("You left the group");
      navigate("/feed-public");
      setLeavePopup(false);
    } catch (error) {
      toast.error("Failed to leave group");
    }
  };

  const handleUpdateGroup = async () => {
    try {
      let groupImage = null;

      if (imageFile) {
        if (group?.groupAvatar) {
          await deleteBlob({ fileUrl: group?.groupAvatar });
        }

        const uploadPath = isForumOnly
          ? `${process.env.REACT_APP_CHAT_UPLOAD}/group/${selectedSubWorkspace?.subWorkSpaceId}/${groupDetails?.id}`
          : `${process.env.REACT_APP_CHAT_UPLOAD}/group/${groupDetails?.id}`;

        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("filePath", uploadPath);
        groupImage = await uploadCitationOnServer(formData);
        setGroup((prev) => ({
          ...prev,
          groupAvatar: groupImage?.data?.data || group?.groupAvatar,
        }));
        setGroupDetails((prev) => ({
          ...prev,
          groupAvatar: groupImage?.data?.data || group?.groupAvatar,
        }));
      }

      await updateGroup({
        ...group,
        groupAvatar: groupImage?.data?.data || group?.groupAvatar,
      });
      setGroupDetails({
        ...group,
        groupAvatar: groupImage?.data?.data || group?.groupAvatar,
      });

      const Message = {
        type: "group_updated",
        content: {
          text: `group details updated by ${loggedInUser?.userName}`
        },
      };
      sendGroupMessage(
        groupDetails?.id,
        loggedInUser?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Error updating group details");
    }
  };

  const updateUserImage = (file, url) => {
    setGroupImage(url);
    setImageFile(file);
  };

  return (
    <div className="flex flex-col w-full gap-5">
      <InfoPopup
        open={leavePopup}
        title="Group Leave"
        description="Are you sure you want to leave this group?"
        btn1={"Leave"}
        btn2={"Cancel"}
        onClickBtn1={handleLeaveGroup}
        onClickBtn2={() => setLeavePopup(false)}
        loading1={isLeaving}
        handleClose={() => setLeavePopup(false)}
      />
      <div className="grid grid-cols-[auto_1fr]  gap-3 w-full place-items-center">
        <div className="w-[150px]">
          <UserAvtarUpload
            setPicture={updateUserImage}
            picture={groupImage}
            userName={group?.name}
            isDisable={!isLoggedInUserAdmin}
          />
        </div>
        <div className="flex flex-col w-full text-debatePalette-title">
          <div className="flex flex-col items-start gap-1 flex-1">
            <label className="text-medium text-debatePalette-title font-semibold">
              Group Name:
            </label>
            <EditableInput
              disable={!isLoggedInUserAdmin}
              value={group?.name}
              onChange={(value) => setGroup({ ...group, name: value })}
            />
          </div>
          <div className="flex flex-col items-start gap-1 flex-1">
            <label className="text-medium text-debatePalette-title font-semibold">
              Group Description:
            </label>
            <EditableTextArea
              disable={!isLoggedInUserAdmin}
              value={group?.description}
              onChange={(value) => setGroup({ ...group, description: value })}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 items-start">
        <label className="text-medium text-debatePalette-title font-semibold">
          Group Member:
        </label>
        <div className="flex gap-3 items-center">
          <div className="flex items-center -space-x-4">
            {groupMembers?.map((member) => (
              <ImageOrInitials
                variant="circular"
                size="h-10 w-10"
                alt={member?.username}
                imageSrc={
                  member?.avatar
                    ? getUserImageUrl(member?.id, member?.avatar)
                    : ""
                }
                initials={member?.username}
              />
            ))}
          </div>
          <span className="text-debatePalette-title">
            {groupMembers?.length} members
          </span>
        </div>
      </div>

      <div className="flex justify-center gap-3 mt-3">
        {isLoggedInUserAdmin && (
          <Button
            variant="filled"
            className="bg-primary text-base px-6 py-1.5 normal-case font-normal cursor-pointer text-debatePalette-title"
            onClick={() => handleUpdateGroup()}
            loading={isUpdating || isUploading}
          >
            Save
          </Button>
        )}
        <Button
          variant="outlined"
          color="red"
          className="text-base px-6 py-1.5 normal-case font-normal cursor-pointer"
          onClick={() => setLeavePopup(true)}
          loading={isLeaving}
        >
          Leave
        </Button>
      </div>
    </div>
  );
};

export default GroupDetailsPopup;
