import { GET_NEWS_LIST } from "../apiRoutes";
import { getDataINSTANCE } from "../controller";

export const getNewsList = async (search,pageNo = 1, pageSize = 10) => {
  const data = await getDataINSTANCE(
    `${GET_NEWS_LIST}?filter=${search}&pageNo=${pageNo}&pageSize=${pageSize}`,
    "Failed to fetch news"
  );
  return data;
};
