import React, { useEffect } from "react";
import Container from "../../Components/UI/Container";
import { useAuth } from "../../Context/AuthContext";
import { useTab } from "../../Context/TabContext";
import Nomination from "./Nomination";
import Notification from "./Notification";

const MainNotification = () => {
  const { tab } = useTab();
  const { user } = useAuth();
 
  useEffect(() => {
    tab === "nomination" && document.getElementById("get").click();
  }, [tab]);

  return (
    <Container>
      <section className="w-full h-full">
        {tab === "nomination" && <Nomination user={user} />}
        {tab === "notification" && (
          <Notification user={user} key={"notification"} />
        )}
      </section>
    </Container>
  );
};

export default MainNotification;
