import React, { useRef, useEffect, Suspense } from "react";
import Loader from "../../Components/UI/Loader";
import { TailSpin } from "react-loader-spinner";

const InfiniteScroll = ({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  children,
  inverse = false,
}) => {
  const observerRef = useRef();

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    const targetElement = observerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchNextPage(); // Fetch next page when in view
        }
      },
      { threshold: 0.2 }
    );

    if (targetElement) observer.observe(targetElement);

    return () => {
      if (targetElement) observer.unobserve(targetElement);
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        {inverse && (
          <div
            ref={observerRef}
            className="flex w-full items-center justify-center p-2 shrink-0"
          >
            {isFetchingNextPage ? (
              <div className="flex w-full gap-3 items-center justify-center ">
                <TailSpin
                  visible={true}
                  height={"30px"}
                  width={"20px"}
                  strokeWidth={3}
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  color="#153C58"
                  wrapperClass=""
                />{" "}
                <span className="text-secondary font-semibold">
                  Loading more...
                </span>
              </div>
            ) : null}
          </div>
        )}
        {children}
        {!inverse && (
          <div
            ref={observerRef}
            className="flex w-full items-center justify-center p-2 shrink-0"
          >
            {isFetchingNextPage ? (
              <div className="flex w-full gap-3 items-center justify-center ">
                <TailSpin
                  visible={true}
                  height={"30px"}
                  width={"20px"}
                  strokeWidth={3}
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  color="#153C58"
                  wrapperClass=""
                />{" "}
                <span className="text-secondary font-semibold">
                  Loading more...
                </span>
              </div>
            ) : null}
          </div>
        )}
      </Suspense>
    </>
  );
};

export default InfiniteScroll;



// import React, { useRef, useEffect, Suspense } from "react";
// import Loader from "../../Components/UI/Loader";
// import { TailSpin } from "react-loader-spinner";

// const InfiniteScroll = ({
//   fetchNextPage,
//   hasNextPage,
//   isFetchingNextPage,
//   isLoading,
//   children,
//   inverse = false,
// }) => {
//   const observerRef = useRef();

//   useEffect(() => {
//     if (!hasNextPage || isFetchingNextPage) return;

//     const targetElement = observerRef.current;

//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           fetchNextPage(); // Fetch next page when in view
//         }
//       },
//       { threshold: 0.2 }
//     );

//     if (targetElement) observer.observe(targetElement);

//     return () => {
//       if (targetElement) observer.unobserve(targetElement);
//     };
//   }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

//   return (
//     <>
//       <Suspense fallback={<Loader />}>
//         {inverse && (
//           <div
//             ref={observerRef}
//             className="flex w-full items-center justify-center p-2 shrink-0"
//           >
//             {isFetchingNextPage ? (
//               <div className="flex w-full gap-3 items-center justify-center ">
//                 <TailSpin
//                   visible={true}
//                   height={"30px"}
//                   width={"20px"}
//                   strokeWidth={3}
//                   ariaLabel="tail-spin-loading"
//                   radius="1"
//                   wrapperStyle={{}}
//                   color="#153C58"
//                   wrapperClass=""
//                 />{" "}
//                 <span className="text-secondary font-semibold">
//                   Loading more...
//                 </span>
//               </div>
//             ) : null}
//           </div>
//         )}
//         {children}
//         {!inverse && (
//           <div
//             ref={observerRef}
//             className="flex w-full items-center justify-center p-2 shrink-0"
//           >
//             {isFetchingNextPage ? (
//               <div className="flex w-full gap-3 items-center justify-center ">
//                 <TailSpin
//                   visible={true}
//                   height={"30px"}
//                   width={"20px"}
//                   strokeWidth={3}
//                   ariaLabel="tail-spin-loading"
//                   radius="1"
//                   wrapperStyle={{}}
//                   color="#153C58"
//                   wrapperClass=""
//                 />{" "}
//                 <span className="text-secondary font-semibold">
//                   Loading more...
//                 </span>
//               </div>
//             ) : null}
//           </div>
//         )}
//       </Suspense>
//     </>
//   );
// };

// export default InfiniteScroll;
