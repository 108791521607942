import { GET_SESSION_HISTORY, POST_QUESTIONS } from "../apiRoutes";
import AXIOS_INSTANCE_AGENT from "../axiosInstanceAgent";

export const postQuestion = async (requestBody) => {
    try {
      const { data } = await AXIOS_INSTANCE_AGENT.post(
        `${POST_QUESTIONS}`,
        requestBody
      );
      return data;
    } catch (error) {
      throw new Error("Failed to fetch ans of question");
    }
  };
export const getSessionHistory = async ({ user_id, session_id, page, pageSize}) => {
    try {
      const { data } = await AXIOS_INSTANCE_AGENT.post(
        `${GET_SESSION_HISTORY}?page=${page}&page_size=${pageSize}`,
        { user_id, session_id }
      );
      return data;
    } catch (error) {
      throw new Error("Failed to fetch session history");
    }
  };