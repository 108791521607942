import moment from "moment";
import React from "react";

const SummaryDisplay = ({ recurrence, startTime, endTime, selectedDays, monthlyDate }) => {
  return (
    <div className="mt-4 p-4 bg-gray-100 rounded-md text-secondary">
      <h2 className="font-semibold text-lg mb-2 ">Schedule Summary</h2>
      <p>
        <strong>Recurrence:</strong> {recurrence}
      </p>
      <p>
        <strong>Time:</strong> {startTime} to {endTime}
      </p>
      {recurrence === "Weekly" && (
        <p>
          <strong>Days:</strong> {selectedDays.join(", ")}
        </p>
      )}
      {recurrence === "Monthly" && (
        <p>
          <strong>Date:</strong> {moment(monthlyDate)?.format("Do")}
        </p>
      )}
    </div>
  );
};

export default SummaryDisplay;
