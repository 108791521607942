import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import BasicButton from "../Buttons/BasicButton";
import URLImage from "../../../Assets/Images/url.png";

export function NewsCard({ news, isBuyNow = false }) {
  const handleImageError = (e) => {
    e.target.src = URLImage;
  };

  return (
    <Card
      shadow={false}
      className="w-full shadow-sm border flex-col sm:flex-row gap-2 items-center sm:items-stretch p-4"
    >
      <CardHeader
        shadow={false}
        floated={false}
        className=" max-w-[100%] sm:max-w-[10rem] shrink-0"
      >
        <Avatar
          variant="rounded"
          src={news?.image}
          alt="cardimage"
          className="h-full max-h-[250px] w-[250px] object-cover"
          onError={handleImageError}
        />
      </CardHeader>
      <CardBody className="py-0 px-1 w-full sm:px-2 flex flex-col gap-2 justify-between m-0 text-wrap truncate">
        {/* <Typography variant="h6" color="gray" className=" uppercase">
            {news?.authors[0].name}
          </Typography> */}
        <div className="flex flex-col gap-2">
          <Typography
            variant="h5"
            color="blue-gray"
            className="text-center sm:text-start"
          >
            {news?.title}
          </Typography>
          <Typography
            color="gray"
            className="font-normal text-justify !line-clamp-4 sm:!line-clamp-4"
          >
            {news?.description} 

          </Typography>
        </div>
        <div className=" flex mt-2 flex-col lg:flex-row items-end lg:justify-between">
          {isBuyNow ? (
            <BasicButton color={"primary"}>Buy Now</BasicButton>
          ) : (
            <a
              onClick={(e) => e.stopPropagation()}
              href={news?.link}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 flex-1 text-justify !line-clamp-1"
            >
              {news?.link}
            </a>
          )}
          <Typography
            color="black"
            className="font-normal text-sm p-0 mt-1 tracking-widest text-end shrink-0"
          >
            {moment(news.createdAt).format("DD-MMM-YYYY")}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
}
