import {
  useElements,
  useStripe,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useQueryClient } from "@tanstack/react-query";

const CheckOutPage = ({ amount, price, product, clientSecret }) => {
  const [search] = useSearchParams();
  const plan = search.get("plan");
  const type = search.get("type");
  const reactStripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    const initPaymentRequest = async () => {
      if (!reactStripe) {
        console.error("Stripe initialization failed.");
        return;
      }

      const pr = reactStripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: `Plan: ${plan}`,
          amount: price?.priceAmount, // Amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
        wallets: ["browserCard", "applePay", "googlePay", "link"],
      });

      pr.canMakePayment().then((result) => {
        console.log("Can Make Payment Result:", result);
        if (result) {
          setPaymentRequest(pr);
        } else {
          console.warn("No supported payment methods available.");
        }
      });
    };

    initPaymentRequest();
  }, [reactStripe, amount, plan]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!reactStripe || !elements) return;

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setErrorMessage(submitError.message);
      setLoading(false);
      return;
    }

    const { paymentIntent, error } = await reactStripe.confirmPayment({
      elements,
      clientSecret: clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.origin}/payment-success?amount=${amount}&plan=${plan}&type=${type}`,
        receipt_email: loggedInUser?.email,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
      return;
    }

    if (paymentIntent.status === "succeeded") {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
      navigate(`/payment-success?amount=${amount}&plan=${plan}&type=${type}`, {
        state: { amount, plan, type },
        replace: true,
      });
      setLoading(false);
      return;
    }

    if (paymentIntent.status === "canceled") {
      setErrorMessage(paymentIntent.payment_method);
      console.log("Payment canceled");

      // navigate(`/payment-success?amount=${amount}&plan=${plan}&type=${type}`, {
      //   state: { amount, plan, type },
      //   replace: true,
      // });
      // setLoading(false);
      return;
    }

    if (paymentIntent.status === "processing") {
      console.log("Payment processing...");

      // setErrorMessage(paymentIntent.payment_method);
      // navigate(`/payment-failed?amount=${amount}&plan=${plan}&type=${type}`, {
      //   state: { amount, plan, type },
      //   replace: true,
      // });
      // setLoading(false);
      return;
    }

    if (paymentIntent.status === "payment_failed") {
      setErrorMessage(paymentIntent.payment_method);
      navigate(`/payment-failed?amount=${amount}&plan=${plan}&type=${type}`, {
        state: { amount, plan, type },
        replace: true,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  if (!reactStripe || !elements) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col w-full gap-2">
      {paymentRequest ? (
        <div className="mb-4">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      ) : null}
      <PaymentElement className="w-full" />
      {errorMessage && (
        <div className="text-debatePalette-timer">{errorMessage}</div>
      )}
      <Button
        className="flex justify-center"
        disabled={loading || !reactStripe}
        type="submit"
        loading={loading}
      >
        {loading ? "Processing..." : `Pay ${amount}`}
      </Button>
    </form>
  );
};

export default CheckOutPage;
