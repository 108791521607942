import { Button } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { Fragment, useRef } from "react";
import toast from "react-hot-toast";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useAddCitation } from "../../Web-Hooks/Debate/use-debate";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import { GetCopiedURL } from "../../api/debate/debate";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useMultiCitationCheckAgent } from "../../Web-Hooks/Agents/use-citation-agent";

const AddCitationInTags = ({
  round,
  debate,
  disable,
  type,
  side,
  argumentId,
  argumentType,
  userArguments,
}) => {
  const menuRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();
  const {
    mutateAsync: uploadCitationOnServer,
    isPending: isUploadCitationPending,
  } = useUploadCitation();
  const queryClient = useQueryClient();
  const {
    mutateAsync: checkMultipleCitation,
    isPending: isCheckCitationLoading,
  } = useMultiCitationCheckAgent();
  const [citationTestLoader, setCitationTestLoader] = React.useState(false);

  const isMotion = type === "motion";

  const motionUploadFilePath = `${process.env.REACT_APP_MOTION_UPLOAD}/${selectedWorkspace?.workSpaceId}`;

  const debateUploadFilePath = `${process.env.REACT_APP_DEBATE_UPLOAD}/${
    selectedWorkspace?.workSpaceId
  }/${debate?.subworkspaceId || selectedSubWorkspace?.subWorkSpaceId}`;

  const createNewUrl = (data, type) => {
    if (type === "motion") {
      return `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${motionUploadFilePath}/${data}`;
    }
    return `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${debateUploadFilePath}/${data}`;
  };

  const getAddCitationPayload = ({ userId, subWorkSpaceId }, { citations }) => {
    const commonPayload = {
      userId,
      subWorkSpaceId,
      citations,
    };

    if (type === "motion") {
      return { ...commonPayload, MotionId: debate?.motionId };
    }

    const debatePayload = {
      ...commonPayload,
      debateId: debate?.debateId,
      bySide: side,
    };

    if (debate?.debateType === "eonian") {
      return {
        ...debatePayload,
        round: debate?.currentRound || 0,
        [argumentType]: argumentId,
      };
    }

    const argumentOfLoggedInUserKeys = Object.entries(
      userArguments?.find(({ userId: id }) => id === loggedInUser?.userId) || {}
    )
      .filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key]) => key);

    const payload = {
      ...debatePayload,
      round: round || debate?.currentRound || 0,
    };

    if (argumentOfLoggedInUserKeys.includes("invitationId")) {
      payload.invitationId = userArguments?.find(
        ({ userId: id }) => id === loggedInUser?.userId
      )?.invitationId;
    }

    if (argumentOfLoggedInUserKeys.includes("argumentId")) {
      payload.argumentId = userArguments?.find(
        ({ userId: id }) => id === loggedInUser?.userId
      )?.argumentId;
    }

    return payload;
  };

  const handleAddCitation = async (selectedCitation) => {
    setCitationTestLoader(true);
    // new AI();

    try {
      let data = await checkMultipleCitation({
        citation_url: [selectedCitation.citationUrl],
        debate_id: debate?.debateId,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
      if (data?.data?.Matched) {
        setCitationTestLoader(false);

        return toast.error("Citation already exists, Please try another.");
      } else if (!data?.data?.Matched) {
        setCitationTestLoader(false);

        const payloadCitation = {
          ...selectedCitation,
          citationUrl:
            selectedCitation?.citationType === "url"
              ? selectedCitation?.citationUrl
              : selectedCitation?.citationUrl?.split("/").pop(),
        };

        const payload = getAddCitationPayload(
          {
            userId: loggedInUser?.userId,
            subWorkSpaceId:
              debate?.subworkspaceId || selectedSubWorkspace?.subWorkSpaceId,
          },
          { citations: [payloadCitation] }
        );

        if (Object.values(payload).some((value) => !value && value !== 0)) {
          toast.error("Failed to add citation, please try again later.");
          return;
        }
        try {
          await addCitation(payload);
          toast.success("Citation added successfully.");
          queryClient.invalidateQueries({ queryKey: ["motionList"] });
        } catch (error) {
          console.log(error);
        }
      }
      // toast.success("Citation added successfully.");
      // queryClient.invalidateQueries({ queryKey: ["motionList"] });
    } catch (error) {
      setCitationTestLoader(false);

      console.log(error);
    }
  };

  const handleCitationSubmit = async (selectedCitation) => {
    if (!selectedCitation.citationFile) {
      if (selectedCitation.citationType === "url") {
        handleAddCitation({
          ...selectedCitation,
        });
        return;
      }
      const filePath = selectedCitation?.citationUrl
        ?.split("debatemodule/")
        ?.pop();
      const data = await GetCopiedURL(
        filePath,
        isMotion ? motionUploadFilePath : debateUploadFilePath
      );
      const newUrl = createNewUrl(data, type);
      handleAddCitation({ ...selectedCitation, citationUrl: newUrl });
      return;
    }
    // Calling AI
    const formdata = new FormData();
    formdata.append("file", selectedCitation.citationFile);
    formdata.append(
      "filePath",
      isMotion ? motionUploadFilePath : debateUploadFilePath
    );
    try {
      const { data } = await uploadCitationOnServer(formdata);
      if (data?.data) {
        handleAddCitation({ ...selectedCitation, citationUrl: data.data });
      }
    } catch (error) {
      toast.error("Error uploading citation", error);
    }
  };

  return (
    <Fragment>
      <CitationComponent
        menuRef={menuRef}
        from={["library", "gallary", "device", "external"]}
        citation={[]}
        debateDetails={[]}
        isLoadingFromHandleSubmit={
          isCheckCitationLoading ||
          isAddCitationPending ||
          isUploadCitationPending
        }
        handleCitationSubmit={async (selectedCitation) =>
          handleCitationSubmit(selectedCitation)
        }
        MenuHandlerComponent={
          <Button
            className="w-full bg-primary text-debatePalette-title flex justify-center"
            loading={isCheckCitationLoading || citationTestLoader || isAddCitationPending}
            disabled={debate?.roundStatus === "completed" ? true : disable}
          >
            Add Citation
          </Button>
        }
      />
    </Fragment>
  );
};

export default AddCitationInTags;
