import { Switch, Typography } from "@material-tailwind/react";
import React from "react";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useAuth } from "../../Context/AuthContext";
import { useUpdateNotificationList } from "../../Web-Hooks/Notification/use-notification";
import toast from "react-hot-toast";

const InvitationNotification = ({ settingInfo, defaultSettings }) => {
  const { user } = useAuth();
  const { mutateAsync: updateStatus, isPending: isUpdating } = useUpdateNotificationList();

  const handleChangeNotification = async (workspace) => {
    if(defaultSettings?.pauseAll){
      toast.error("Please unpause all notification to change the settings");
      return;
    }
    const AllEnableIds = settingInfo
      ?.filter((sub) => sub.debatebaseId && sub.isEnable === true)
      .map((sub) => sub.debatebaseId);

    const isAlreadyEnabled = AllEnableIds.includes(workspace.debatebaseId);

    const updateSetting = {
      UserId: user.userId,
      DebateBaseInvitation: {
        DebatebaseId: [workspace?.debatebaseId],
        IsEnable: !isAlreadyEnabled,
      },
    };
    return await updateStatus(updateSetting);
  };

  return (
    <>
      <Typography
        variant="h6"
        className="text-debatePalette-title font-semibold py-3"
      >
        Debatebase Invitation
      </Typography>
      <hr className="border-gray-300" />
      <form className="flex flex-col gap-4 overflow-y-auto py-3">
        {/* <div className="flex w-full items-center  justify-between">
          <label htmlFor="all" color="blue-gray" className="font-medium">
            Pause All
          </label>
          <Switch
            id="all"
            name="all"
            color="blue"
            checked={settingInfo?.PauseAllNotification}
            // onClick={() =>
            //   handleChangeNotification("Invitation", !settingInfo.PauseAllNotification)
            // }
            onChange={() =>
              handleChangeNotification(
                "Invitation",
                !settingInfo.PauseAllNotification
              )
            }
            containerProps={{
              className: "flex-shrink-0 p-0 w-16",
            }}
          />
        </div>
        <hr className="border-gray-300" /> */}

        {settingInfo?.map((workspace) => (
          <div className="flex w-full items-center justify-between" key={workspace?.debatebaseId}>
            <Tooltip
              position="top-start"
              content={workspace?.debatebaseName}
              containerClass="w-full"
            >
              <label
                htmlFor={workspace?.debatebaseId}
                color="blue-gray"
                className="font-medium line-clamp-1 overflow-x-hidden"
              >
                {workspace?.debatebaseName}
              </label>
            </Tooltip>
            <Switch
              id={workspace?.debatebaseId}
              name={workspace?.debatebaseId}
              color="blue"
              checked={workspace?.isEnable}
              disabled={isUpdating}
              onChange={() => handleChangeNotification(workspace)}
              containerProps={{
                className: "flex-shrink-0 p-0 w-16",
              }}
            />
          </div>
        ))}
      </form>
    </>
  );
};

export default InvitationNotification;
