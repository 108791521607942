import ImageOrInitials from "../../Functionality/ImageOrInitials";

export default function WorkspaceStack({Member, size = "h-8 w-8"}) {
  return (
    <div className="flex items-center -space-x-4">

      {
        Member?.map((member, index) => index < 4 && <ImageOrInitials userStatus={member?.online} showUserStatus={!!member?.online} key={member?.subWorkSpaceInvitationId} imageSrc={member?.userImage} initials={member?.userName || member?.email} size={size}/>)
      }
    </div>
  );
}
