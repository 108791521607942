import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import CheckOutPage from "../../Pages/SubscriptionPlan/CheckOutPage";
import {
  useCreateSubscription,
  useGetProductDetails
} from "../../Web-Hooks/Subscription/use-subscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  apiVersion: "2024-12-18.acacia",
});

export default function PaymentGateWay() {
  const [search] = useSearchParams();
  const amount = search.get("amount");
  const plan = search.get("plan");
  const type = search.get("type");
  const planId = search.get("planId");

  const { data: products, isLoading: isProductLoading } = useGetProductDetails(
    planId,
    type
  );
  const { user: loggedInUser } = useAuth();
  const [clientSecret, setClientSecret] = useState(null);

  const findProduct = products?.find((product) => product.productName === plan);
  const findPrice = findProduct?.prices?.find(
    (price) => price.recurringInterval === type
  );

  // const { mutateAsync: createCustomer } = useCreateCustomer();
  // const { mutateAsync: createPaymentIntent } = useCreatePaymentIntent();
  const { mutateAsync: createSubscription } = useCreateSubscription();

  useEffect(() => {
    const initializePayment = async () => {
      if (!loggedInUser || !findPrice) return;
      try {
        // const { customerData } = await createCustomer({
        //   userId: loggedInUser?.userId,
        //   Email: loggedInUser?.email,
        //   Name: loggedInUser?.userName,
        // });

        // if (!customerData) {
        //   toast.error("Error creating customer");
        //   return;
        // }

        const data = await createSubscription({
          customerEmail: loggedInUser?.email,
          customerName: loggedInUser?.userName,
          priceId: findPrice.priceId,
        });

        const { clientSecret } = data;

        if (!clientSecret) {
          return;
        }

        setClientSecret(clientSecret);
      } catch (error) {
        console.error("Error initializing payment:", error);
      }
    };

    if (loggedInUser) {
      initializePayment();
    }
  }, [loggedInUser, findPrice]);

  if (isProductLoading || !clientSecret)
    return (
      <div className="h-screen flex justify-center items-center w-full">
        <LoadingSpinner /> {/* Animated loading spinner */}
      </div>
    );

  return (
    <div className="h-screen">
      <div className="flex flex-col justify-center items-center w-full h-full space-y-4">
        {findProduct && findPrice && clientSecret ? (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
              },
              loader: "always",
            }}
          >
            <Card className="border-secondary border rounded-lg p-2 w-full sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[40%] 2xl:w-[30%]">
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 rounded-none border-b border-white/10 text-center"
              >
                <Typography variant="h3" className="text-secondary">
                  Plan: {plan}
                </Typography>
              </CardHeader>
              <CardBody>
                <div id="payment-element">
                  <CheckOutPage
                    clientSecret={clientSecret}
                    amount={amount}
                    product={findProduct}
                    price={findPrice}
                  />
                </div>
              </CardBody>
            </Card>
          </Elements>
        ) : (
          <div className="h-screen flex justify-center items-center w-full">
            <h1 className="text-2xl font-bold">No Plan Found</h1>
          </div>
        )}
      </div>
    </div>
  );
}
