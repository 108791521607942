import React, { useState } from "react";
import DebateCard from "../../Components/UI/Cards/DebateCard";
import { useGetUserDebates } from "../../Web-Hooks/Profile/use-profile";
import NoData from "../../Components/Functionality/NoData";
import { useWorkspace } from "../../Context/WorkspaceContext";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const UserDebates = ({ otherUserId }) => {
  const { selectedWorkspace } = useWorkspace();
  const [citationPopup, setCitationPopup] = useState(false);
  const [debate, setDebate] = useState(null);
  const {
    data,
    isLoading: isDebateListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetUserDebates(otherUserId, selectedWorkspace?.workSpaceId);

  const debateList = data?.pages?.flatMap((page) => page?.debateList);

  return (
    <div className="flex flex-col gap-3">
      <CitationTagListPopup
        debate={debate}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setDebate}
      />
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isDebateListLoading}
      >
        {isDebateListLoading ? RenderLoaderSkeleton(3) : null}
        {!debateList || debateList?.length !== 0 ? (
          debateList?.map((debate, i) => (
            <DebateCard
              debate={debate}
              key={debate?.debateId}
              setCitationPopup={setCitationPopup}
              setDebate={setDebate}
              type={"user"}
            />
          ))
        ) : (
          <NoData message={"No debates found"} />
        )}
      </InfiniteScroll>
    </div>
  );
};

export default UserDebates;
