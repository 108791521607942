import React, { useState } from "react";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import {
  Card,
  List,
  ListItem,
  Radio,
  Typography,
} from "@material-tailwind/react";
import { REPORTS_MESSAGES } from "../../Utils/Constant";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import {
  useBlockUser,
  useReportUser,
} from "../../Web-Hooks/Profile/use-profile";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import InfoPopup from "../../Components/Popup/InfoPopup";
import Swal from "sweetalert2";

const ReportPopup = ({
  loggedInUser,
  selectedSubWorkspace,
  selectedWorkspace,
  type,
  debateId,
  confirmPopup,
  setConfirmPopup,
  setReportPopup,
  reportPopup,
  popupTitle,
  setPopupTitle,
  userId,
}) => {
  const navigate = useNavigate();
  const [selectedReport, setSelectedReport] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const { mutate: blockUser } = useBlockUser();
  const { mutateAsync: reportUser, isPending: isReporting } = useReportUser();

  const handleReportSelect = (e, value) => {
    e.stopPropagation();
    setSelectedReport(value);
  };

  const handleBlockUser = async (e) => {
    e.stopPropagation();
    try {
      const blockBody = {
        BlockedUserId: userId,
        // BlockFrom: "Admin",
        BlockFrom: "UserToUser",
        // BlockFrom: "DebateWise",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
      };

      if (Object.values(blockBody).some((value) => !value)) {
        toast.error(`Failed to block ${type}, please try again later.`);
        setConfirmPopup(false);
        setPopupTitle("");
        return;
      }

      await blockUser(blockBody);
      toast.success("User blocked successfully");
      setConfirmPopup(false);
      setPopupTitle("");
      navigate("/feed-public");
    } catch (error) {
      console.log("Something went wrong");
      setConfirmPopup(false);
      setPopupTitle("");
    }
  };

  const handleReportUser = async () => {
    const reportObj = {
      SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
      ReportReason:
        selectedReport === "Something else" ? otherReason : selectedReport,
      ReportByUserId: loggedInUser?.userId,
    };
    if (Object.values(reportObj).some((value) => !value)) {
      toast.error(`Failed to report ${type}, please try again later.`);
      setReportPopup(false);
      return;
    }

    if (type === "debate") {
      reportObj.DebateId = debateId;
    } else {
      reportObj.UserId = userId;
    }
    try {
      const res = await reportUser(reportObj);

      if (res) {
        setReportPopup(false);
        toast.success(`Successfully reported ${type}`);
        return res;
      }
    } catch (error) {
      setReportPopup(false);
      toast.error(`Failed to report ${type}, please try again later.`);
    }
  };
  return (
    <div>
      {confirmPopup && (
        <InfoPopup
          open={confirmPopup}
          handleClose={(e) => {
            e.stopPropagation();
            setConfirmPopup(false);
            setReportPopup(false);
          }}
          btn1={"cancel"}
          onClickBtn1={(e) => {
            e.stopPropagation();
            setConfirmPopup(false);
            setReportPopup(false);
          }}
          btn2={"Confirm"}
          onClickBtn2={
            popupTitle === "Block" ? handleBlockUser : handleReportUser
          }
          description={`Are you sure you want to ${popupTitle} this ${
            popupTitle === "Block" ? "user" : `${type}`
          }?`}
          loading2={isReporting}
          title={popupTitle}
        />
      )}
      {reportPopup && (
        <EditableBodyPopup
          size={"sm"}
          title={"Report"}
          open={reportPopup}
          handleClose={(e) => {
            e.stopPropagation();
            setReportPopup(false);
            setPopupTitle("");
          }}
          btn2={"Report"}
          onClickBtn2={(e) => {
            e.stopPropagation();
            Swal.fire({
              title: "Report",
              text: `Are you sure you want to report this ${type}?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, report it!",
              customClass: {
                container: "!z-[99999]",
              },
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                try {
                  const result = await handleReportUser(); // Call your async function
                  return result; // Pass data to the `then` block of Swal
                } catch (error) {
                  Swal.showValidationMessage(`Error: ${error.message}`);
                }
              },
              allowOutsideClick: () => !Swal.isLoading(),
            });
          }}
          disableBtn={
            selectedReport === "Something else"
              ? otherReason
                ? false
                : true
              : !selectedReport
          }
          children={
            <div className="overflow-y-auto">
              <Typography variant="lead" color="black" className="font-bold">
                Why are you reporting this {type}?
              </Typography>
              <Typography variant="small" color="black" className="font-normal">
                Your report is anonymous, excepts if you're reporting an
                intellectual property infringement.
              </Typography>
              <Card className="h-72 overflow-y-auto">
                <List className="">
                  {REPORTS_MESSAGES.map((item) => (
                    <ListItem
                      className="py-1 flex flex-col gap-1 items-start"
                      key={item?.id}
                      onClick={(e) => handleReportSelect(e, item?.message)}
                    >
                      <Radio
                        ripple={false}
                        label={item?.message}
                        checked={selectedReport === item?.message}
                        onChange={(e) => handleReportSelect(e, item?.message)}
                        color="blue"
                        name="report"
                      />
                      {selectedReport === "Something else" &&
                        selectedReport === item?.message && (
                          <DebateTextArea
                            id={"report reason"}
                            maxLength={100}
                            placeholder="please write your reason here"
                            value={otherReason}
                            handleChange={(e) => setOtherReason(e.target.value)}
                          />
                        )}
                    </ListItem>
                  ))}
                </List>
              </Card>
            </div>
          }
        />
      )}
    </div>
  );
};

export default ReportPopup;
