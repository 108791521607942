import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateSelector = ({ value, setValue }) => {
  const handleDateChange = (selectedDate) => {
    // Adjust the date to the last valid date of the month if needed
    const daysInMonth = moment(selectedDate).daysInMonth();
    const adjustedDate = moment(selectedDate).date(
      Math.min(moment(selectedDate).date(), daysInMonth)
    );
    setValue(adjustedDate.toDate());
  };

  return (
    <div>
      <label className="block text-lg font-semibold text-debatePalette-title mb-1">
        Select Date <span className="text-debatePalette-timer">*</span>
      </label>
      <DatePicker
        showDatePicker
        selected={value}
        onChange={(date) => handleDateChange(date)}
        inline
      />
    </div>
  );
};

export default DateSelector;
