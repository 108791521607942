import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LinkExpiry from "../../Assets/Images/LinkExpiry.png";
import { Button, Typography } from "@material-tailwind/react";
import { useAuth } from "../../Context/AuthContext";
import Container from "../../Components/UI/Container";

const ContactUs = () => {
  const [search] = useSearchParams();
  const showForm = search.get("contactUs") === "true";
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (showForm) {
      // Dynamically load the HubSpot form script when `showForm` is true
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "48851040",
            formId: "827c98dd-dd94-4666-b0f1-d66eee090f72",
            target: "#hubspotFormContainer",
            onsubmit: () => {
              navigate("/feed-public", { replace: true });
            },
          });
        }
        setLoading(false);
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showForm]);

  if (!showForm && loading) {
    return (
      <section className="h-screen flex flex-col md:flex-row items-center justify-between p-8">
        <div className="flex flex-col justify-center items-start gap-4 md:w-1/3 w-full">
          <Typography variant="h1">Loading...</Typography>
          <Typography variant="lead">
            Please wait while we load the page.
          </Typography>
        </div>
      </section>
    );
  }

  if (!showForm) {
    return (
      <section className="h-screen flex flex-col md:flex-row items-center justify-between p-8">
        <div className="flex flex-col justify-center items-start gap-4 md:w-1/3 w-full">
          <Typography variant="h1">
            Oops, something went wrong while loading the page.
          </Typography>
          <Typography variant="lead">
            Something went wrong while loading the page. Please try again later.
          </Typography>
          <Button
            className="bg-primary text-secondary font-semibold"
            onClick={() =>
              navigate(user ? "/feed-public" : "/", { replace: true })
            }
          >
            Back to {user ? "Home" : "Login"}
          </Button>
        </div>
        <div className="flex flex-col w-full h-full md:w-2/3 md:h-2/3 justify-center items-center">
          <img
            src={LinkExpiry}
            alt=""
            className="h-full w-full sm:w-2/3 xl:w-1/2"
          />
        </div>
      </section>
    );
  }

  return (
    <Container>
      {showForm && (
        <div className="h-full w-full justify-center flex flex-col gap-3 items-center">
          <Typography variant="h1">Contact Us</Typography>
          <div
            id="hubspotFormContainer"
            className="w-[80%] h-full border border-secondary rounded-md p-10"
          />
        </div>
      )}
    </Container>
  );
};

export default ContactUs;
