import React, { useState } from "react";
import DebateAddCitation from "../DebateAddCitation";
import Logo from "../../../Assets/Images/DB_AI.png";
import AICitationSuggestion from "../../../Components/Functionality/AICitationSuggestion";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import useDebounce from "../../../Services/useDebounce";
import { useCitationSuggestAgent } from "../../../Web-Hooks/Agents/use-citation-agent";
import { CITATION_SUGGESTION } from "../../../Utils/Constant";
import { useNavigate } from "react-router-dom";
import { handleAIUpgradePlan } from "../../../Services/SweetAlert";
import { usePlan } from "../../../Context/PlanContext";

const AddCitationWithAI = () => {
  const { debateInfo, handleStateUpdate, citation } = useCreateDebateContext();
  const argument = useDebounce(debateInfo.CreatorOpeningArgument, 1000);
  const [showAIValidator, setShowAIValidator] = useState(false);
  const [activeBlinkingButton, setActiveBlinkingButton] = useState();
  const { currentPlan } = usePlan();
  const navigate = useNavigate();
  const { data: citationAgent, isLoading: isCitationLoading } = useCitationSuggestAgent(argument,currentPlan?.aiFeature );

  const handleCitationSuggester = () => {
    if (!currentPlan?.aiFeature) {
      handleAIUpgradePlan(CITATION_SUGGESTION, () => navigate(`/upgrade-plan`));
      return;
    }
    setShowAIValidator(true);
  };

  return (
    <div>
      <AICitationSuggestion
        open={showAIValidator}
        debateInfo={debateInfo}
        handleClose={() => setShowAIValidator(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => setActiveBlinkingButton(false)}
        setActiveBlinkingButton={setActiveBlinkingButton}
        argument={argument}
        citationAgent={citationAgent}
        isLoading={isCitationLoading}
      />
      <div
        className="flex flex-row items-center  w-full"
        onMouseEnter={() => setActiveBlinkingButton(true)}
        onMouseLeave={() => setActiveBlinkingButton(false)}
      >
        <div className="flex flex-col items-end w-full">
          {argument && citation.length < currentPlan?.maxCitationCount && (
            <button
              id="add-citation-with-ai"
              onClick={handleCitationSuggester}
              className={`rounded-full text-whiter ${
                activeBlinkingButton ? "animate-blinker" : ""
              }`}
            >
              <img
                src={Logo}
                alt="Logo"
                height="30"
                width="30"
                className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
              />
            </button>
          )}
          <DebateAddCitation argument={argument} limitCitation={currentPlan?.maxCitationCount} />

          {/* {isButtonBlinking("Citation") && (
                <CheckCircleIcon color="green" className="h-7 w-7" />
              )} */}
        </div>
      </div>
    </div>
  );
};

export default AddCitationWithAI;
