import React, { useEffect, useState } from "react";
import AccordionNav from "./AccordionNav";
import {
  useGetDebatedUsers,
  useGetVotedUsers,
} from "../../Web-Hooks/Debate/use-users";

const PublicFeedBar = ({ selectedWorkspace, loggedInUser, pathname, currentPlan }) => {
  const [open, setOpen] = useState(0);

  const handleOpen = (update) => {
    setOpen(open === update ? 0 : update);
  };

  useEffect(() => {
    const path = pathname.slice(1).split("/")[0];
    if (path === "debated") {
      setOpen("debated");
    } else if (path === "voted") {
      setOpen("voted");
    } else if (path === "private") {
      setOpen("private");
    } else if (path === "message") {
      setOpen("message");
    } else if (path === "debated-public") {
      setOpen("public-debated");
    } else if (path === "voted-public") {
      setOpen("public-voted");
    } else {
      setOpen(0);
    }
  }, [pathname]);

  const { data: recentVotedUsers } = useGetVotedUsers(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId
  );
  const { data: recentDebatedUsers } = useGetDebatedUsers(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId
  );
  const PublicFeedBarOptions = [
    {
      option: "Feed ",
      optionLabel: "(Public)",
      subOption: [],
      route: "/feed-public",
      name: "public-feed",
      hasAccess: true

    },
    {
      option: "Debated",
      optionLabel: "(Public)",
      name: "public-debated",
      subOption: recentDebatedUsers,
      route: "/debated-public",
      hasAccess: true

    },
    {
      option: "Voted",
      optionLabel: "(Public)",
      name: "public-voted",
      subOption: recentVotedUsers,
      route: "/voted-public",
      hasAccess: true

    },
    {
      option: "Reference",
      subOption: [],
      route: "/reference",
      name: "reference",
      hasAccess: true

    },
    {
      option: "News",
      subOption: [],
      route: "/news",
      name: "news",
      hasAccess: currentPlan?.accessNews
    },
  ];
  return (
    <div>
      {PublicFeedBarOptions.length > 0 ? (
        <AccordionNav
          open={open}
          pathname={pathname}
          Options={PublicFeedBarOptions}
          handleClose={() => {}}
          handleOpen={handleOpen}
          loggedInUser={loggedInUser}
        />
      ) : null}
    </div>
  );
};

export default PublicFeedBar;
