import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  AddCitation,
  AddDebateToSubWorkspace,
  AddNextRoundArgument,
  AddNomination,
  AddOpponentArgument,
  AddOtherUserArgument,
  AddUpdateScalerVote,
  ConvertToOpenDebate,
  getDebateCitationList,
  getDebateDetails,
  getDebateDetailsPage,
  getDebateResult,
  GetDebateTypeDetails,
  getUserArgumentList,
  NominateNewUserToDebate,
  RemoveCitation,
  UpdateRedebateCount,
} from "../../api/debate/debate";
import {
  getPrivateDebateList,
  getPublicDebateList,
  getPublicUserWiseDebatedList,
  getPublicUserWiseVotedList,
  getSemiPublicDebateList,
  getSemiPublicUserWiseDebatedList,
  getSemiPublicUserWiseVotedList,
} from "../../api/debate/get_debate";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { updateDynamicCitationCountDebateList } from "../../Utils/Helper";

export const useGetDebateTypeDetails = () => {
  return useQuery({
    queryKey: ["debate-details"],
    queryFn: async () => {
      return await GetDebateTypeDetails();
    },
    select: (data) => data,
  });
};

export const useGetDebateDetails = (debateId, userId, status) => {
  return useQuery({
    queryKey: ["debate-details", debateId, userId, status],
    queryFn: async () => {
      return await getDebateDetails(debateId, userId);
    },
    select: (data) => data,
    enabled: !!userId && !!debateId,
  });
};
export const useGetDebateDetailsPage = (debateId, round, sortBy, userId) => {
  return useQuery({
    queryKey: ["debate-details-page", debateId, round, sortBy, userId],
    queryFn: async () => {
      return await getDebateDetailsPage(debateId, round, sortBy, userId);
    },
    select: (data) => data,
    enabled: !!debateId || !!userId || !!sortBy || !!round,
  });
};
export const useGetDebateResult = (debateId) => {
  return useQuery({
    queryKey: ["resultByDebateId", debateId],
    queryFn: async () => {
      return await getDebateResult(debateId);
    },
    select: (data) => data,
    enabled: !!debateId,
  });
};

export const useGetSemiPublicDebateList = (userId, subWorkspaceId) => {
  return useInfiniteQuery({
    queryKey: ["feed-subworkspace", userId, subWorkspaceId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getSemiPublicDebateList(
        userId,
        subWorkspaceId,
        pageParam
      );
      return response; // Assuming `message` contains the paginated data
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined; // Load next page if available
    },

    enabled: !!userId && !!subWorkspaceId, // Only fetch if both userId and subWorkspaceId are available
  });
};

export const useGetSemiPublicUserWiseVotedList = (
  userId,
  subWorkspaceId,
  opponentId
) => {
  return useInfiniteQuery({
    queryKey: ["voted-subworkspace", userId, subWorkspaceId, opponentId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getSemiPublicUserWiseVotedList(
        userId,
        subWorkspaceId,
        opponentId,
        pageParam
      );
      return response; // Assuming response contains pagination info
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!subWorkspaceId,
  });
};

export const useGetSemiPublicUserWiseDebatedList = (
  userId,
  subWorkspaceId,
  opponentId
) => {
  return useInfiniteQuery({
    queryKey: ["debated-subworkspace", userId, subWorkspaceId, opponentId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getSemiPublicUserWiseDebatedList(
        userId,
        subWorkspaceId,
        opponentId,
        pageParam
      );
      return response;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!subWorkspaceId,
  });
};

export const useGetPrivateUserWiseDebatedList = (
  userId,
  workSpaceId,
  opponentId
) => {
  return useInfiniteQuery({
    queryKey: ["private-subworkspace", userId, workSpaceId, opponentId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getPrivateDebateList(
        userId,
        workSpaceId,
        opponentId,
        pageParam
      );
      return response;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!workSpaceId,
  });
};

export const useGetPublicDebateList = (userId, workSpaceId) => {
  return useInfiniteQuery({
    queryKey: ["public-feed-workspace", userId, workSpaceId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getPublicDebateList(
        userId,
        workSpaceId,
        pageParam
      );
      return response;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!workSpaceId,
  });
};

export const useGetPublicUserWiseDebatedList = (
  userId,
  workSpaceId,
  opponentId
) => {
  return useInfiniteQuery({
    queryKey: ["public-debated-workspace", userId, workSpaceId, opponentId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getPublicUserWiseDebatedList(
        userId,
        workSpaceId,
        opponentId,
        pageParam
      );
      return response;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!workSpaceId,
  });
};

export const useGetPublicUserWiseVotedList = (
  userId,
  workSpaceId,
  opponentId
) => {
  return useInfiniteQuery({
    queryKey: ["public-voted-workspace", userId, workSpaceId, opponentId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getPublicUserWiseVotedList(
        userId,
        workSpaceId,
        opponentId,
        pageParam
      );
      return response;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!workSpaceId,
  });
};

export const useAddDebateToSubWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddDebateToSubWorkspace(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useConvertToOpenDebate = () => {
  return useMutation({
    mutationFn: async (Debate) => {
      return await ConvertToOpenDebate(Debate);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useNominateNewUserToDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await NominateNewUserToDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddOpponentArgument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddOpponentArgument(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["user"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAddOtherUserArgument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddOtherUserArgument(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddNextRoundArgument = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await AddNextRoundArgument(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useUpdateRedebateCount = () => {
  return useMutation({
    mutationFn: async (Debate) => {
      return await UpdateRedebateCount(Debate);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAddUpdateScalerVote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (Debate) => {
      return await AddUpdateScalerVote(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details"],
        })
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useRemoveCitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Citation) => {
      return await RemoveCitation(Citation);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["debate-citations"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddCitation = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [search] = useSearchParams();
  const query = search.get("query");
  const { pathname } = location;
  const opponentId = pathname.slice(1).split("/")[1];
  const { user } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  return useMutation({
    mutationFn: async (Citation) => {
      const data = await AddCitation(Citation);
      return {
        ...data,
        userId: user?.userId,
        workSpaceId: selectedWorkspace?.workSpaceId,
        subWorkspaceId: Citation?.subWorkSpaceId,
        opponentId: opponentId,
      };
    },
    onSuccess: async (data) => {
      if (!data) {
        console.error("Data is undefined!");
        return;
      }

      const { userId, subWorkspaceId, workSpaceId, debateId, opponentId } =
        data;
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ["debate-details-page"] }),
        queryClient.setQueryData(
          ["private-subworkspace", userId, subWorkspaceId, opponentId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "privateDebateLists"
            )
        ),
        queryClient.setQueryData(
          ["debated-subworkspace", userId, subWorkspaceId, opponentId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "debateList"
            )
        ),
        queryClient.setQueryData(
          ["voted-subworkspace", userId, subWorkspaceId, opponentId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "votedDebateList"
            )
        ),
        queryClient.setQueryData(
          ["feed-subworkspace", userId, subWorkspaceId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "debateList"
            )
        ),
        queryClient.setQueryData(
          ["other-user-debates", opponentId, userId, workSpaceId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "debateList"
            )
        ),
        queryClient.setQueryData(
          ["search-debates", userId, workSpaceId, query],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "debatedLists"
            )
        ),
        queryClient.setQueryData(
          ["public-voted-workspace", userId, workSpaceId, opponentId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "votedDebateList"
            )
        ),
        queryClient.setQueryData(
          ["public-debated-workspace", userId, workSpaceId, opponentId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "debateList"
            )
        ),
        queryClient.setQueryData(
          ["public-feed-workspace", userId, workSpaceId],
          (oldData) =>
            updateDynamicCitationCountDebateList(
              oldData,
              debateId,
              "debateList"
            )
        ),
        queryClient.invalidateQueries({ queryKey: ["debate-citations"] }),
        queryClient.invalidateQueries({ queryKey: ["debate-arguments"] }),
        queryClient.invalidateQueries({ queryKey: ["motion-citations"] }),
      ]);
    },

    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddNomination = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Nomination) => {
      return await AddNomination(Nomination);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["debate-details-page"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useGetDebateCitationList = (debateId) => {
  return useQuery({
    queryKey: ["debate-citations", debateId],
    queryFn: async () => {
      return await getDebateCitationList(debateId);
    },
    select: (data) => data.listOfCitation,
    enabled: !!debateId,
  });
};
export const useGetUserDebateArgumentList = (userId, debateId, type) => {
  return useQuery({
    queryKey: ["debate-arguments", userId, debateId],
    queryFn: async () => {
      return await getUserArgumentList(userId, debateId);
    },
    select: (data) => data.arguments,
    enabled: !!userId && !!debateId && type,
  });
};
