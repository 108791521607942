import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Chip,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-tailwind/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditPencil } from "../../../Assets/Svg/EditPencil.svg";
import { usePlan } from "../../../Context/PlanContext";

export function SideBarMenu({
  label,
  options,
  isArrow = true,
  setOpenPopup,
  isWorkSpaceEdit = false,
  isMobileLayout = false,
}) {
  const [toggle, setToggle] = useState();
  const { currentPlan } = usePlan();
  const navigate = useNavigate();

  const restrictionForOptions = (option) => {
    switch (option) {
      case "create-debate":
        return currentPlan?.createDebate;
      case "suggest-motion":
        return currentPlan?.suggestMotion;
      case "find-motion":
        return currentPlan?.findMotion;
      default:
        return true;
    }
  };

  const handleClick = (route, state, isAccessible) => {
    if (!isAccessible) {
      navigate(`/upgrade-plan`)
      return;
    }
    navigate(route, {
      state: { selectedDebate: state },
    })
  }

  const renderMenuItem = (option, index) => {
    const isAccessible = restrictionForOptions(option?.id);

    return (
      <MenuItem
        className="flex gap-3 p-2 justify-between items-center text-debatePalette-title font-medium"
        key={index}
        onClick={
          option?.route
            ? () => handleClick(option.route, option.state, isAccessible)
            : handleClick
        }
      >
        {option?.label}
        {isAccessible ? (
          !isMobileLayout && <ChevronRightIcon className="h-4 w-4" />
        ) : (
          <Tooltip content={"Upgrade to Pro"}>
            <Chip
              size="sm"
              variant="outlined"
              value={"Pro"}
              className="cursor-pointer text-secondary border-secondary bg-transparent group-hover:border-white group-hover:text-white"
            />
          </Tooltip>
        )}
      </MenuItem>
    );
  };
  return (
    <Menu
      ripple={false}
      placement="bottom-start"
      open={toggle}
      handler={() => setToggle(!toggle)}
    >
      <MenuHandler>
        <div className="flex w-full justify-between">
          <Button
            id="debate-options"
            ripple={false}
            variant="text"
            className="flex p-0 gap-2 w-full justify-center font-normal capitalize tracking-normal hover:bg-transparent active:bg-transparent"
          >
            <span className="flex justify-center text-xl text-debatePalette-background">
              {label}
            </span>
            {isArrow && (
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3.5 w-3.5 transition-transform mt-1 text-debatePalette-background ${
                  toggle ? "rotate-180" : ""
                }`}
              />
            )}
          </Button>
          {isWorkSpaceEdit && (
            <EditPencil
              className="h-6 w-6 text-white cursor-pointer hover:text-debatePalette-title"
              onClick={() => setOpenPopup(true)}
            />
          )}
        </div>
      </MenuHandler>
      <MenuList>
        {options.map((option, index) => renderMenuItem(option, index))}
      </MenuList>
    </Menu>
  );
}
