import React, { createContext, useContext, useState } from "react";
import { useAuth } from "./AuthContext";
/**
 * @typedef {Object} Plan
 * @property {string} planName
 * @property {boolean} createWorkspace
 * @property {boolean} createForum
 * @property {boolean} joinForum
 * @property {boolean} actionDebate
 * @property {boolean} createDebate
 * @property {boolean} suggestMotion
 * @property {boolean} findMotion
 * @property {boolean} aiFeature
 * @property {boolean} messageOption
 * @property {boolean} libraryOption
 * @property {boolean} roleManagement
 * @property {boolean} createReference
 * @property {boolean} privateDebate
 * @property {number} maxCreateWorkspaceCount
 * @property {number} maxCreateForumCount
 * @property {number} maxCitationCount
 * @property {number} maxAddUserPerForum
 * @property {string} libraryStorage
 * @property {string} totalWorkspaceJoin
 * @property {string[]} storageOptions
 * @property {boolean} accessReference
 * @property {boolean} accessNews
 * @property {boolean} createGroupMessage
 * @property {boolean} accessAddCitation
 * @property {boolean} accessSaveDebate
 */

/** @type {React.Context<{currentPlan: Plan,  updateFeature: Function, updatePlan: Function}>} */

const PlanContext = createContext({
  currentPlan: null,
  updateFeature: () => {},
  updatePlan: () => {},
});

const defaultPlans = {
    Enterprise: {
      planName: 'Enterprise',
      createWorkspace: true,
      joinWorkspace: true,
      maxCreateWorkspaceCount: 15,
      totalWorkspaceJoin: "unlimited",
      createForum: true,
      maxCreateForumCount: 20,
      maxAddUserPerForum:200,
      libraryOption: true,
      libraryStorage: "1TB",
      storageOptions: ["doc", "image", "pdf", "links","video"],
      createReference: true,
      accessReference: true,
      messageOption: true,
      createGroupMessage: true,
      accessNews: true,
      actionDebate: true,
      accessAddCitation: true,
      accessSaveDebate:true,
      createDebate: true,
      suggestMotion: false,
      findMotion: true,
      aiFeature: false,
      roleManagement: true,
      privateDebate:true,
      maxCitationCount: 10,
    },
    Team:  {
      planName: 'Team',
      createWorkspace: false,
      joinWorkspace: true,
      maxCreateWorkspaceCount: 0,
      totalWorkspaceJoin: 5,
      createForum: false,
      maxCreateForumCount: 10,
      maxAddUserPerForum:0,
      libraryOption: true,
      libraryStorage: "5GB",
      storageOptions: ["doc", "image", "pdf", "links","video"],
      createReference: false,
      accessReference: true,
      messageOption: true,
      createGroupMessage: true,
      accessNews: true,
      actionDebate: true,
      accessAddCitation: false,
      accessSaveDebate:false,
      createDebate: false,
      suggestMotion: false,
      findMotion: true,
      aiFeature: false,
      roleManagement: false,
      privateDebate:false,
      maxCitationCount: 2,
    },
    Free: {
      planName: 'Free',
      createWorkspace: true,
      joinWorkspace: true,
      totalWorkspaceJoin: 2,
      maxCreateWorkspaceCount: 1,
      createForum: true,
      maxCreateForumCount: 1,
      maxAddUserPerForum:10,
      libraryOption: true,
      libraryStorage: "1GB",
      storageOptions: ["doc", "image", "pdf", "links"],
      createReference: false,
      accessReference: true,
      messageOption: true,
      createGroupMessage: false,
      accessNews: false,
      accessAddCitation: false,
      accessSaveDebate:false,
      createDebate: false,
      suggestMotion: false,
      findMotion: false,
      aiFeature: false,
      roleManagement: false,
      privateDebate:false,
      maxCitationCount: 1,
    },
    Pro: {
      planName: 'Pro',
      createWorkspace: true,
      joinWorkspace: true,
      maxCreateWorkspaceCount: 5,
      totalWorkspaceJoin: "unlimited",
      createForum: true,
      maxCreateForumCount: 5,
      maxAddUserPerForum:50,
      libraryOption: true,
      libraryStorage: "10GB",
      storageOptions: ["doc", "image", "pdf", "links","video"],
      createReference: false,
      accessReference: true,
      messageOption: true,
      createGroupMessage: true,
      accessNews: true,
      actionDebate: true,
      accessAddCitation: true,
      accessSaveDebate:true,
      createDebate: true,
      suggestMotion: true,
      findMotion: true,
      aiFeature: false,
      roleManagement: true,
      privateDebate:true,
      maxCitationCount: 5,
    },
  };

const getDefaultPlan = (planName) => {
  return defaultPlans[planName] || defaultPlans["Free"];
};

export const PlanProvider = ({ children }) => {
  const { user } = useAuth();
  const [currentPlan, setCurrentPlan] = useState(getDefaultPlan("Free"));
  // const { data: subscribedPlan } = useGetSubscribedPlan(user?.userId);

  const setPlanByApi = (data) => {
    const plan = getDefaultPlan(data.planName);
    setCurrentPlan(plan);
  };

  const updateFeature = (feature, value) => {
    setCurrentPlan((prevPlan) => {
      if (!(feature in prevPlan)) {
        throw new Error(`Unknown feature: ${feature}`);
      }
      return {
        ...prevPlan,
        [feature]: value,
      };
    });
  };

  const updatePlan = (planName) => {
    const newPlan = getDefaultPlan(planName);
    setCurrentPlan(newPlan);
  };

  // useEffect(() => {
  //   if (subscribedPlan) {
  //     setPlanByApi(subscribedPlan);
  //   }
  // }, [subscribedPlan]);

  return (
    <PlanContext.Provider
      value={{ currentPlan, setPlanByApi, updateFeature, updatePlan }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error("usePlan must be used within a PlanProvider");
  }
  return context;
};

// Example usage inside a component
// const { currentPlan, setPlanByApi, updateFeature, updatePlan } = usePlan();
