import React from "react";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import NoData from "../../Components/Functionality/NoData";
import ReferenceCard from "./ReferenceCard";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const ReferenceList = ({
  referenceList,
  user,
  isReferenceLoading,
  setSelectedReference,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  return (
    <div className="flex flex-col gap-5 w-full h-full p-1">
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isReferenceLoading}
      >
        {isReferenceLoading && RenderLoaderSkeleton(3)}
        {referenceList?.length === 0 && !isReferenceLoading && (
          <NoData message={"No Reference Found"} size={"w-full h-[80%]"} />
        )}
        {referenceList?.length > 0 &&
          referenceList?.map((reference) => (
            <ReferenceCard
              setSelectedReference={setSelectedReference}
              key={reference?.referenceId}
              user={user}
              reference={reference}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default ReferenceList;
