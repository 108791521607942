import { ArrowRightIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import DocImage from "../../../Assets/Images/doc.png";
import PDFImage from "../../../Assets/Images/pdf.png";
import URLImage from "../../../Assets/Images/url.png";
import VIDImage from "../../../Assets/Images/video.png";
import { ReactComponent as DoubleChecked } from "../../../Assets/Svg/DoubleChecked.svg";
import { ReactComponent as SingleChecked } from "../../../Assets/Svg/SibgleChecked.svg";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { useAuth } from "../../../Context/AuthContext";
import { useFileViewer } from "../../../Context/FileViewerContext";
import {
  extractTitle,
  formatTimestampToReadableDate,
  getUserImageUrl,
  isTodayOrYesterday,
} from "../../../Utils/Helper";

const MessageCard = ({ message, receiver, setSelectedMessage }) => {
  const { handleFile } = useFileViewer();
  const { content, senderId, timestamp } = message;

  const { user } = useAuth();
  const sender = senderId;

  const renderContent = () => {
    switch (content?.type) {
      case "text":
        return <span>{content?.content?.text}</span>;

      case "image":
        return (
          <img
            src={content?.content?.url}
            className="w-72 sm:w-80 lg:w-96 cursor-zoom-in"
            alt="message-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "image",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "doc":
        return (
          <img
            src={DocImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="document-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "document",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "pdf":
        return (
          <img
            src={PDFImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="pdf-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "pdf",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "video":
        return (
          <img
            src={VIDImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="video-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "video",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "url":
        return (
          <img
            src={URLImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="video-img"
            onClick={() => {
              window.open(content?.content?.url, "_blank");
            }}
          />
        );
      case "profile":
        return (
          <div className="flex flex-col gap-2">
            <Card className="max-w-96 sm:max-w-96 lg:w-[500px] lg:max-w-[500px]">
              <CardBody className="p-3">
                <div
                  key={content?.content?.userId}
                  className="flex items-center justify-between"
                >
                  <div className="grid grid-cols-[auto_1fr] items-center gap-x-3">
                    <ImageOrInitials
                      imageSrc={content?.content?.userImage}
                      initials={content?.content?.userName}
                      className="shrink-0"
                      size="w-12 h-12"
                    />
                    <div className="flex flex-col gap-1">
                      <p
                        color="blue-gray"
                        className="text-lg font-bold capitalize text-debatePalette-title line-clamp-1"
                      >
                        {content?.content?.userName}
                      </p>
                      <p
                        variant="small"
                        className="text-base text-secondary font-semibold line-clamp-1"
                      >
                        {content?.content?.workspaceName}
                      </p>
                      <Link
                        to={`/profile/${content?.content?.userId}?userName=${content?.content?.userName}`}
                        className="flex items-center gap-x-2 text-[13px] text-debatePalette-link hover:underline"
                      >
                        View Profile <ArrowRightIcon className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      case "motion":
        return (
          <div className="flex flex-col gap-2">
            <Card className="max-w-96 sm:max-w-96 lg:w-[500px] lg:max-w-[500px]">
              <CardBody className="p-3">
                <div
                  key={content?.content?.motionId}
                  className="flex items-center w-full justify-between"
                >
                  <div className="flex items-center gap-x-3">
                    <div className="flex flex-col gap-2">
                      <p className="text-secondary text-base font-semibold leading-tight line-clamp-3">
                        {content?.content?.motion}
                      </p>
                      <Typography
                        variant="small"
                        className="truncate text-[13px] text-secondary line-clamp-1 overflow-y-scroll"
                      >
                        Posted in {content?.content?.subworkspaceName}
                      </Typography>
                      <div className="flex gap-2 items-center justify-between">
                        <Typography
                          variant="small"
                          className="truncate text-[13px] text-secondary line-clamp-1 overflow-y-scroll"
                        >
                          {formatTimestampToReadableDate(
                            content?.content?.createAt
                          )}
                        </Typography>

                        <Link
                          onClick={() => {}}
                          to={`/find-motion?search=${content?.content?.motion}`}
                          className="flex items-center text-[13px] text-debatePalette-link hover:underline"
                        >
                          View Motion
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );

      case "debate":
        return (
          <Card className="max-w-96 sm:max-w-96 lg:w-[500px] lg:max-w-[500px]">
            <CardBody className="p-3">
              <div
                key={content?.content?.userId}
                className="flex items-center w-full justify-between"
              >
                <div className="grid w-full grid-cols-[auto_1fr] items-center gap-x-3">
                  <ImageOrInitials
                    imageSrc={content?.content?.userImage}
                    initials={content?.content?.userName}
                    className="shrink-0 w-10 h-10"
                    size="w-12 h-12"
                  />
                  <div className="flex flex-col w-full gap-2">
                    <Typography
                      color="blue-gray"
                      className="capitalize"
                      variant="h6"
                    >
                      {content?.content?.userName}
                    </Typography>
                    <p className="line-clamp-3 text-justify text-normal text-secondary leading-tight font-semibold">
                      {content?.content?.motion}
                    </p>
                    <Typography
                      variant="small"
                      className="truncate text-[13px] text-secondary line-clamp-1 overflow-y-scroll"
                    >
                      Posted in {content?.content?.workspaceName}
                    </Typography>
                    <div className="flex gap-2 items-center justify-between">
                      <Typography
                        variant="small"
                        className="truncate text-[13px] text-secondary"
                      >
                        {formatTimestampToReadableDate(
                          content?.content?.createAt
                        )}
                      </Typography>
                      <Link
                        onClick={() => {}}
                        to={`/debate/${content?.content?.debateId}?round=${content?.content?.currentRound}`}
                        className="flex items-center text-[13px] text-debatePalette-link hover:underline"
                      >
                        View Debate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        );
      default:
        return <span>Unsupported message type</span>;
    }
  };

  const handleDeleteMessage = async () => {
    setSelectedMessage(message);
  };
  return (
    <div
      className={`message-container w-full ${
        sender === user.userId ? "justify-end sm:pl-[25%] pl-0" : "justify-start sm:pr-[25%] pr-0"
      }`}
      key={message.id}
    >
      {sender !== user.userId && (
        <div className="shrink-0 mr-4">
          <ImageOrInitials
            imageSrc={getUserImageUrl(receiver?.id, receiver?.avatar)}
            initials={receiver?.username}
          />
        </div>
      )}
      <div
        className={`flex-col flex ${
          sender === user.userId ? "items-end" : "items-start"
        }`}
      >
        <sub
          className={`flex flex-row gap-1 ${
            sender === user.userId ? "justify-end" : "justify-start"
          } items-start pb-2 shrink-0`}
        >
          <span
            className={`text-xs text-center font-semibold capitalize ${
              sender === user.userId ? "hidden" : "block"
            }`}
          >
            {receiver?.username},
          </span>
          <span className="text-xs">
            {isTodayOrYesterday(timestamp) === "Today"
              ? moment(timestamp).fromNow()
              : moment(timestamp).format("HH:mm")}
          </span>
          {senderId === user.userId &&
            (message?.isRead ? (
              <DoubleChecked className="w-4 text-debatePalette-link h-4" />
            ) : (
              <SingleChecked className="w-4 text-debatePalette-base h-4 " />
            ))}
        </sub>
        <div className="flex gap-1 group ">
          <div
            className={`message-bubble  ${
              sender === user.userId ? "sent" : "received"
            }`}
          >
            {renderContent()}
          </div>
          {sender === user.userId && (
            <div className="hidden justify-end shrink-0 mr-4 group-hover:flex ">
              <IconButton
                className="p-0"
                variant="text"
                size="sm"
                onClick={handleDeleteMessage}
              >
                <TrashIcon className="h-4 w-4 text-debatePalette-timer" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
