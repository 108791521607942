import {
  CREATE_CUSTOMER,
  CREATE_PAYMENT_INTENT,
  CREATE_SUBSCRIPTION,
  GET_PRODUCT,
  GET_SUBSCRIPTION_PLAN,
  GET_USER_SUBSCRIPTION_PLAN,
} from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";

export const getSubscriptionPlans = async () => {
  const data = await AXIOS_INSTANCE.get(`${GET_SUBSCRIPTION_PLAN}`);
  return data;
};
export const getUserSubscribedPlan = async (userId) => {
  const data = await AXIOS_INSTANCE.get(
    `${GET_USER_SUBSCRIPTION_PLAN}?userId=${userId}`
  );
  return data;
};

export const getProductDetails = async (planId, plan) => {
  const data = await AXIOS_INSTANCE.get(
    `${GET_PRODUCT}?active=true&planId=${planId}&planType=${plan === "month" ? "monthly" : plan === "year" ? "yearly" : ""}`
  );
  return data;
};
export const createCustomer = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_CUSTOMER}`, user
  );
  return data.data;
};
export const createPaymentIntent = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_PAYMENT_INTENT}`, user
  );
  return data.data;
};
export const createSubscription = async (user) => {
  const data = await AXIOS_INSTANCE.post(
    `${CREATE_SUBSCRIPTION}`, user
  );
  return data.data;
};
