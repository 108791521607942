export const CHAT_HISTORY = "/chat/messages";
export const CHAT_UNREAD_COUNT = "/chat/total-unread-count";
export const GET_RECENT_USERS = "/user/get-all-users";
export const GET_USER_DATA = "/user/get-user-by-id";
export const GET_WORKSPACE_USER = "/user/get-workspace-user";
export const DELETE_MESSAGE = "/chat/delete-message";
export const CLEAR_CHAT = "/chat/clear-chat";
export const GET_USERS = "/user/get-group-users";

// Group Chats Routes
export const CREATE_GROUP_CHAT = "/group/create-group";
export const UPDATE_GROUP = "/group/update-group";
export const ADD_USER_TO_GROUP = "/group/add-member";
export const GET_USER_GROUP_LIST = "/group/get-groups"
export const GET_GROUP_CHAT = "/group/get-group-messages-by-group-id"
export const LEAVE_GROUP = "/group/leave-group"
export const CLEAR_CHAT_GROUP = "/group/clear-chat-for-user" 
export const REMOVE_USER_FROM_GROUP = "/group/remove-member"
export const MAKE_MEMBER_ADMIN = "/group"

