import { Tab, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import NominatedCard from "../../Components/UI/Cards/NominatedCard";
import { NotificationTabs } from "../../Utils/Constant";
import { useGetNominationByUserIdAndSubId } from "../../Web-Hooks/Notification/use-notification";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const Nomination = ({ user }) => {
  const [subTab, setSubTab] = useState("get");
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useGetNominationByUserIdAndSubId(
    user?.userId,
    subTab
  );

  const nomination = data?.pages?.flatMap((page) => page.listOfNominations)

  return (
    <div className="w-full h-full">
      <Tabs className="h-full" value={subTab}>
        <TabsHeader
          className={`bg-white w-full sm:w-96  p-0`}
          indicatorProps={{
            className: `shadow-none !text-black bg-transparent`,
          }}
        >
          {NotificationTabs[0].subTabs.map((opt, i) => (
            <Tab
              value={opt.value}
              key={i}
              id={opt.value}
              onClick={() => setSubTab(opt.value)}
              className={`border border-gray-500 ${
                subTab === opt.value &&
                "bg-primary rounded-md font-bold border-primary"
              } ${
                NotificationTabs[0].subTabs.length - 1 === i &&
                "rounded-none rounded-br-3xl "
              } ${i === 0 && "rounded-none  rounded-tl-3xl "}`}
            >
              {opt.label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="h-[calc(100vh-11.5rem)]">
          <InfiniteScroll
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
          >
            {nomination?.length > 0 ? (
              nomination?.map((notification, i) => (
                <NominatedCard
                  key={notification.notificationId}
                  notification={notification}
                >
                  {notification?.type === "get" && (
                    <NominatedCard.Buttons notification={notification} />
                  )}
                </NominatedCard>
              ))
            ) : (
              <NoData
                message={"No Nomination"}
                size={"w-full h-[80%]"}
              ></NoData>
            )}
          </InfiniteScroll>
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Nomination;
