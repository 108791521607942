// useArgumentAgent.js
import { useMutation, useQuery } from "@tanstack/react-query";
import { checkCitationAgent, checkMultiCitationAgent, suggestCitationAgent } from "../../api/agents/agentsApi";
import { useAuth } from "../../Context/AuthContext";

export const useCitationCheckAgent = (url,  debate_motion) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["citation-validate", debate_motion,url],
    queryFn: async () => {
      return await checkCitationAgent({ debate_motion, url, user_id: loggedInUser?.userId, sessionid: loggedInUser?.sessionId });
    },
    select: (data) => data.data,
    enabled:!!url && !!debate_motion && !!loggedInUser,
  });
};
export const useMultiCitationCheckAgent = () => {
  // return useQuery({
  //   queryKey: ["citation-validate", url],
  //   queryFn: async () => {
  //     return await checkCitationAgent({
  //       url: url,
  //       debate_id: debate_id,
  //       workspace_id: workspace_id,
  //       user_id: user_id,
  //       sessionId: sessionId
  //      });
  //   },
  //   select: (data) => data.data,
  //   enabled:!!url && !!loggedInUser,
  // });
  return useMutation({
    mutationFn: async (Citation) => {
      return await checkMultiCitationAgent(Citation);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useCitationSuggestAgent = (debate_argument, enabled) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["citation-suggest", debate_argument],
    queryFn: async () => {
      return await suggestCitationAgent({ debate_argument, user_id: loggedInUser?.userId, sessionid: loggedInUser?.sessionId });
    },
    select: (data) => data.data,
    enabled:!!debate_argument && !!loggedInUser && !!enabled,
  });
};
