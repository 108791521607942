import React from "react";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import Container from "../../Components/UI/Container";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetPublicDebateList } from "../../Web-Hooks/Debate/use-debate";
import Posts from "../Posts/Posts";

const PublicHome = () => {
  const location = useLocation();
  const { pathname } = location;
  const { user } = useAuth();
  const { selectedWorkspace } = useWorkspace();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetPublicDebateList(user?.userId, selectedWorkspace?.workSpaceId);

  return (
    <Container>
      {/* Title */}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
        >
          <Posts
            apiType={pathname}
            user={user}
            isLoading={isLoading && !isFetchingNextPage}
            AllDebates={data?.pages?.flatMap((page) => page.debateList)} // Combine all pages
          />
        </InfiniteScroll>
    </Container>
  );
};

export default PublicHome;
