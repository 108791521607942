import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  SaveDebate,
  UpdateLikeToDebate,
  UpdateVoteToDebate,
} from "../../api/debate/debate";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  updateDynamicSaveCountDebateList
} from "../../Utils/Helper";

export const useAddVoteToDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await UpdateVoteToDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-voted-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["voted-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAddLikeToDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await UpdateLikeToDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["sub-workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useSaveDebate = () => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const [search] = useSearchParams();
  const query = search?.get("search");
  const opponentId = pathname.slice(1).split("/")[1];
  const { selectedWorkspace } = useWorkspace();
  const invalidateOnSave = [
    {
      queryKey: "debate-details-page",
      path: "/debate/",
    },
  ];
  const setQueryOnSave = [
    {
      queryKey: "search-debates",
      path: "search",
      listName: "debatedLists",
      query: ['user','work','search']
    },
    {
      queryKey: "other-user-debates",
      path: "profile",
      listName: "debateList",
      query: ['opponent','user','work']

    },

    {
      queryKey: "public-feed-workspace",
      path: "feed-public",
      listName: "debateList",
      query:['user','work']
    },
    {
      queryKey: "public-debated-workspace",
      path: "debated-public",
      listName: "debateList",
      query:['user','work','opponent']

    },
    {
      queryKey: "public-voted-workspace",
      path: "voted-public",
      listName: "votedDebateList",
      query:['user','work','opponent']

    },
    {
      queryKey: "feed-subworkspace",
      path: "feed",
      listName: "debateList",
      query:['user','sub']

    },
    {
      queryKey: "debated-subworkspace",
      path: "debated",
      listName: "debateList",
      query:['user','sub', 'opponent']

    },
    {
      queryKey: "voted-subworkspace",
      path: "voted",
      listName: "votedDebateList",
      query:['user','sub', 'opponent']

    },
    {
      queryKey: "private-subworkspace",
      path: "private",
      listName: "privateDebateLists",
      query:['user','sub', 'opponent']

    },
  ];

  const handleApiUpdate = (data) => {
    const { userId, subWorkSpaceId, workSpaceId, debateId, opponentId } = data;
    const isPathIncluded = invalidateOnSave.find((item) =>
      pathname.includes(item.path)
    );
    const isPathIncludedSetQuery = setQueryOnSave.find((item) =>
      pathname.includes(item.path)
    );
    if (isPathIncluded) {
      queryClient.invalidateQueries({
        queryKey: [isPathIncluded.queryKey],
      });
    }
    if (isPathIncludedSetQuery) {
      const dynamicQueryKey = [
        isPathIncludedSetQuery.queryKey,
        ...isPathIncludedSetQuery.query.map((key) => {
          switch (key) {
            case "user":
              return userId;
            case "work":
              return workSpaceId;
            case "sub":
              return subWorkSpaceId;
            case "opponent":
              return opponentId;
            case "search":
              return query; // Assuming `query` is fetched from `useSearchParams`
            default:
              return null;
          }
        }).filter(Boolean), // Remove undefined or null values
      ];
  
      queryClient.setQueryData(
        dynamicQueryKey,
        (oldData) =>
          updateDynamicSaveCountDebateList(
            oldData,
            debateId,
            isPathIncludedSetQuery.listName
          )
      );
    }
  };
  return useMutation({
    mutationFn: async (Debate) => {
      const data = await SaveDebate(Debate);
      return {
        ...data,
        workSpaceId: selectedWorkspace?.workSpaceId,
        opponentId: opponentId,
      };
    },
    onSuccess: async (data) => handleApiUpdate(data),
    onError: (error) => {
      console.log(error);
    },
  });
};
