import React, { useEffect, useState } from "react";
// import RichTextEditor fr./MessageScreen
import { Typography } from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import { useAuth } from "../../../Context/AuthContext";
import { SocketEvents, useSocket } from "../../../Context/SocketContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { useClearChat } from "../../../Web-Hooks/Chat/chat";
import { useBlockUser } from "../../../Web-Hooks/Profile/use-profile";
import MessageInput from "./MessageInput";
import MessageMenu from "./MessageMenu";
import InfiniteScrollMessageScreen from "./InfiniteScrollMessageScreen";

const Message = () => {
  const location = useLocation();
  const { state } = location;
  const { user } = state;
  const { user: loggedInUser } = useAuth();
  const { socket } = useSocket();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const { mutate: blockUser, isPending: blockUserLoading } = useBlockUser();
  const { mutate: clearChat, isPending: clearChatPending } = useClearChat();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [clearChatPopup, setClearChatPopup] = useState(false);
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true"
  const CitationOption = [
    {
      id: 1,
      label: "Clear Chat",
      value: "clear",
      handleClick: () => {
        setClearChatPopup(true);
      },
    },
    // {
    //   id: 2,
    //   label: "Block User",
    //   value: "block",
    //   handleClick: () => {
    //     setConfirmationPopup(true);
    //   },
    // },
  ];

  const handleBlockUser = () => {
    try {
      const blockBody = {
        BlockedUserId: user?.userId,
        BlockFrom: "UserToUser",
        // BlockFrom: "DebateWise",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
      };
      const res = blockUser(blockBody);
      if (res) {
        toast.success("User blocked successfully", "block");
      }
    } catch (error) {
      console.log("Something went wrong", "error");
    }
  };

  const handleClearChat = async () => {
    try {
      const chatObj = {
        senderId: loggedInUser?.userId,
        receiverId: user?.userId,
        workspaceId: selectedWorkspace?.workSpaceId,
        subworkspaceId: isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
      };
      await clearChat(chatObj);
      setClearChatPopup(false);
    } catch (error) {
      console.log("Something went wrong", "error");
      setClearChatPopup(false);
    }
  };
  useEffect(() => {
    if (socket && loggedInUser && user) {

      if (!socket.connected) socket.connect();
      socket.emit(SocketEvents.JOIN_ROOM, {
        senderId: loggedInUser?.userId,
        receiverId: user?.userId,
      });

      socket.emit(SocketEvents.MARK_ALL_AS_READ, {
        senderId: loggedInUser?.userId,
        receiverId: user?.userId,
        workspaceId: selectedWorkspace?.workSpaceId,
        subworkspaceId: isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
      });

      // readMessage(loggedInUser?.userId, user?.userId);
    }
  }, [socket, loggedInUser, user, selectedWorkspace, selectedSubWorkspace, isForumOnly]);

  return (
    <section className="grid grid-rows-[auto_1fr] gap-0">
      <InfoPopup
        open={confirmationPopup}
        btn1={"Cancel"}
        btn2={"Block"}
        loading2={blockUserLoading}
        onClickBtn2={handleBlockUser}
        onClickBtn1={() => setConfirmationPopup(false)}
        title="Block User"
        description="Are you sure you want to block this user?"
        handleClose={() => setConfirmationPopup(false)}
      />
      <InfoPopup
        open={clearChatPopup}
        btn1={"Cancel"}
        btn2={"Clear"}
        loading2={clearChatPending}
        onClickBtn2={handleClearChat}
        onClickBtn1={() => setClearChatPopup(false)}
        title="Clear User"
        description="Are you sure you want to clear this chat? All messages will be deleted."
        handleClose={() => setClearChatPopup(false)}
      />
      <div className="sticky top-0 bg-white z-10">
        <div className="px-3 md:px-8 h-12 md:h-16 flex justify-between items-center">
          <div className="flex">
            <Typography variant="h4" className="text-2xl capitalize">
              {user?.userName || user?.email?.split("@")[0]}
            </Typography>
          </div>

          <div className="relative">
            <MessageMenu options={CitationOption} />
          </div>
        </div>
        <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      </div>
      <div className="flex flex-col w-full h-[calc(100vh-10.5rem)] md:h-[calc(100vh-8.4rem)]">
        <div className="flex-1 overflow-y-auto" id="scrollableDiv">
          <InfiniteScrollMessageScreen user={user} />
        </div>
        <div className="bg-transparent px-6 pb-2">
          <MessageInput sender={loggedInUser} receiver={user} />
        </div>
      </div>
    </section>
  );
};

export default Message;
