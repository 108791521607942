// TODO: Add Search Filter
import {
  Avatar,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-tailwind/react";
import React from "react";
import { getInitials } from "../../Utils/Helper";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { usePlan } from "../../Context/PlanContext";

const WorkspaceMenu = ({ navigate, loggedInUser }) => {
  const { workspaceList, selectWorkspace, selectedWorkspace } = useWorkspace();
  const { selectSubWorkspace, selectWorkspaceId } = useSubWorkspace();
  const { currentPlan } = usePlan();

  const handleSelectWorkspace = async (workspace) => {
    selectWorkspace(workspace);

    if (workspace) {
      const subworkspaceList = await getSubWorkspaceByWorkId(
        workspace.workSpaceId,
        loggedInUser?.userId
      );

      const subworkspace = subworkspaceList?.listOfSubworkspaces[0];
      selectWorkspaceId(workspace.workSpaceId);
      selectSubWorkspace(subworkspace);
      navigate("/feed-public", { replace: true });
    }
  };

  const userCreatedWorkspace = workspaceList?.filter(
    (workspace) => workspace?.createdBy?.userId === loggedInUser?.userId
  );

  const pilotPlanRestriction =
    userCreatedWorkspace?.length <= currentPlan.maxCreateWorkspaceCount &&
    currentPlan.createWorkspace;
  return (
    <Menu placement="bottom">
      <MenuHandler>
        {selectedWorkspace?.userImage === "" ||
        !selectedWorkspace?.userImage ? (
          <Button className="relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[100px] h-12 max-h-[100px] text-xs bg-white text-secondary shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-full">
            <Tooltip
              className="bg-debatePalette-background text-debatePalette-title border"
              content={"debatebase"}
            >
              <span className="absolute transform -translate-x-1/2 font-bold text-lg -translate-y-1/2 top-1/2 left-1/2">
                {selectedWorkspace?.workSpaceName &&
                  getInitials(selectedWorkspace?.workSpaceName)}
              </span>
            </Tooltip>
          </Button>
        ) : (
          <Avatar
            src={selectedWorkspace?.userImage}
            className="h-12 w-12 rounded-full obj p-1 shadow-md"
            alt="Profile"
          />
        )}
      </MenuHandler>
      <MenuList className="">
        <div className="gap-3 max-h-48 overflow-y-auto hover:border-none hover:outline-none">
          {workspaceList &&
            workspaceList?.map((workspace) => (
              <MenuItem
                id={workspace?.workSpaceId}
                key={workspace?.workSpaceName}
                className="flex items-center p-1 justify-between gap-3 min-w-60"
                onClick={() => handleSelectWorkspace(workspace)}
              >
                <div className="flex items-center p-1 justify-start gap-3">
                  {workspace?.profileImage ? (
                    <Avatar
                      src={workspace?.profileImage}
                      alt={workspace?.username}
                      className="h-10 w-10"
                    />
                  ) : (
                    <div className="inline-flex items-center justify-center h-10 w-10 uppercase font-bold shadow-lg border border-primary-basic text-secondary bg-white  rounded-full">
                      {getInitials(workspace?.workSpaceName)}
                    </div>
                  )}
                  <p className="text-md font-semibold text-secondary xs:line-clamp-1  max-w-[180px]">
                    {workspace?.workSpaceName}
                  </p>
                </div>
              </MenuItem>
            ))}
        </div>
        {pilotPlanRestriction && (
          <>
            <hr className="my-3" />
            <MenuItem
              id="createWorkspace"
              className="flex items-center p-1 justify-start gap-3 min-w-60"
              onClick={() => {
                navigate("/createworkspace");
              }}
            >
              {
                <PlusIcon className="h-10 w-10 text-debatePalette-background rounded-full bg-secondary" />
              }
              <p className="text-md font-semibold text-secondary">
                Add a debatebase
              </p>
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default WorkspaceMenu;
