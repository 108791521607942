import {
  Card,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  Radio,
  Typography,
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Block } from "../../../Assets/Svg/Block.svg";
import { ReactComponent as FilledShare } from "../../../Assets/Svg/FilledShare.svg";
import { ReactComponent as Report } from "../../../Assets/Svg/Report.svg";
import { useAuth } from "../../../Context/AuthContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { REPORTS_MESSAGES } from "../../../Utils/Constant";
import { getUTCDate } from "../../../Utils/Helper";
import {
  useBlockUser,
  useReportUser,
} from "../../../Web-Hooks/Profile/use-profile";
import EditableBodyPopup from "../../Popup/EditableBodyPopup";
import InfoPopup from "../../Popup/InfoPopup";
import DebateTextArea from "../Inputs/DebateTextArea";
import MenuListRender from "./MenuListRender";
import Swal from "sweetalert2";

function ThreeDotIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
  );
}

export function ThreeDotMenu({
  debate,
  otherUser,
  debateId,
  type = "debate",
  setShareDebatePopup,
  setDebate,
  currentPlan,
}) {
  const { user: loggedInUser } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { mutateAsync: blockUser } =
    useBlockUser();
  const [open, setOpen] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const { mutateAsync: reportUser } = useReportUser();
  const navigate = useNavigate();

  const sameUser = otherUser?.userId === loggedInUser?.userId;

  const PostThreeDotMenu = [
    {
      SVG: FilledShare,
      item: `${debateId ? "Share Debate" : "Share Profile"}`,
      isHRline: false,
      value: type === "debate" ? "shareDebate" : "shareProfile",
      handleClick: (e) => handleClick(e, "share"),
    },
    {
      SVG: Report,
      item: "Report",
      isHRline: false,
      isRedText: true,
      value: "report",
      handleClick: (e) => handleClick(e, "report"),
      isLoggedInUser: sameUser,
    },
    {
      SVG: Block,
      item: "Block User",
      isHRline: false,
      value: "blockUser",
      handleClick: (e) => handleClick(e, "blockUser"),
      isLoggedInUser: sameUser,
    },
  ];

  const handleClick = async (e, value) => {
    e.stopPropagation();
    if (value === "share") {
      handleShare(e);
    } else if (value === "report") {
      setReportPopup(true);
    } else if (value === "blockUser") {
      Swal.fire({
        title: "Block",
        text: `Are you sure you want to block this user?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, block it!",
        customClass: {
          container: "!z-[99999]",
        },
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const result = await handleBlockUser(); // Call your async function
            return result; // Pass data to the `then` block of Swal
          } catch (error) {
            Swal.showValidationMessage(`Error: ${error.message}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    }
    e.stopPropagation();
    setOpen(false);
  };

  const handleShare = async (e) => {
    e.stopPropagation();
    if (type === "debate") {
      const copyDebate = {
        debateId: debateId,
        userId: debate?.creatorId,
        userName: debate?.creatorUserName,
        userImage: debate?.creatorImage,
        workspaceId: selectedWorkspace?.workSpaceId,
        workspaceName: selectedWorkspace?.workSpaceName,
        subworkspaceId: debate?.subworkspaceId,
        subworkspaceName: debate?.subworkspaceName,
        createdAt: debate?.createdAt,
        motion: debate?.motionOrClaim,
        currentRound: debate?.currentRound,
        totalRound: debate?.totalRound,
      };
      setDebate(copyDebate);
      setShareDebatePopup(true);
    } else if (type === "user") {
      const copyProfile = {
        userId: otherUser?.userId,
        userName: otherUser?.userName,
        userImage: otherUser?.userImage,
        workspaceId: selectedWorkspace?.workSpaceId,
        workspaceName: selectedWorkspace?.workSpaceName,
      };
      setDebate(copyProfile);
      setShareDebatePopup(true);
    }
  };

  const handleBlockUser = async () => {
    try {
      const blockBody = {
        BlockedUserId: otherUser?.userId,
        BlockFrom: "UserToUser",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        CreateAt: getUTCDate(),
      };

      if (Object.values(blockBody).some((value) => !value)) {
        toast.error(`Failed to block ${type}, please try again later.`);
        return;
      }
      const res = await blockUser(blockBody);
      if (res) {
        toast.success("User blocked successfully");
        navigate("/feed-public");
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  const handleReportUser = async (e) => {
    const reportObj = {
      SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
      ReportReason:
        selectedReport === "Something else" ? otherReason : selectedReport,
      ReportByUserId: loggedInUser?.userId,
    };
    if (Object.values(reportObj).some((value) => !value)) {
      toast.error(`Failed to report ${type}, please try again later.`);
      setReportPopup(false);
      return;
    }

    if (type === "debate") {
      reportObj.DebateId = debateId;
    } else {
      reportObj.UserId = otherUser?.userId;
    }

    try {
      const res = await reportUser(reportObj);

      if (res) {
        setReportPopup(false);
        toast.success(`Successfully reported ${type}`);
        return res;
      }
    } catch (error) {
      setReportPopup(false);
      toast.error(`Failed to report ${type}, please try again later.`);
    }
  };

  const handleReportSelect = (e, value) => {
    e.stopPropagation();
    setSelectedReport(value);
  };

  return (
    <Fragment>
      {reportPopup && (
        <EditableBodyPopup
          size={"sm"}
          title={"Report"}
          open={reportPopup}
          handleClose={(e) => {
            e.stopPropagation();
            setReportPopup(false);
          }}
          btn2={"Report"}
          onClickBtn2={(e) => {
            e.stopPropagation();
            Swal.fire({
              title: "Report",
              text: `Are you sure you want to report this ${type}?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, report it!",
              customClass: {
                container: "!z-[99999]",
              },
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                try {
                  const result = await handleReportUser(); // Call your async function
                  return result; // Pass data to the `then` block of Swal
                } catch (error) {
                  Swal.showValidationMessage(`Error: ${error.message}`);
                }
              },
              allowOutsideClick: () => !Swal.isLoading(),
            });
          }}
          disableBtn={
            selectedReport === "Something else"
              ? otherReason
                ? false
                : true
              : !selectedReport
          }
          children={
            <div className="overflow-y-auto">
              <Typography variant="lead" color="black" className="font-bold">
                Why are you reporting this {type}?
              </Typography>
              <Typography variant="small" color="black" className="font-normal">
                Your report is anonymous, excepts if you're reporting an
                intellectual property infringement.
              </Typography>
              <Card className="h-72 overflow-y-auto">
                <List className="">
                  {REPORTS_MESSAGES.map((item) => (
                    <ListItem
                      className="py-1 flex flex-col gap-1 items-start"
                      key={item?.id}
                      onClick={(e) => handleReportSelect(e, item?.message)}
                    >
                      <Radio
                        ripple={false}
                        label={item?.message}
                        checked={selectedReport === item?.message}
                        onChange={(e) => handleReportSelect(e, item?.message)}
                        color="blue"
                        name="report"
                      />
                      {selectedReport === "Something else" &&
                        selectedReport === item?.message && (
                          <DebateTextArea
                            id={"report-reason"}
                            maxLength={100}
                            placeholder="please write your reason here"
                            value={otherReason}
                            handleChange={(e) => setOtherReason(e.target.value)}
                          />
                        )}
                    </ListItem>
                  ))}
                </List>
              </Card>
            </div>
          }
        />
      )}
      <Menu
        placement="bottom-end"
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        open={open}
        handler={setOpen}
      >
        <MenuHandler>
          <IconButton
            variant="text"
            // className={`${sameUser || !currentPlan?.actionDebate ? "hidden" : "block"}`}
          >
            <ThreeDotIcon />
          </IconButton>
        </MenuHandler>
        <MenuList className="!z-40">
          {PostThreeDotMenu.map((option, i) => (
            <MenuListRender
              ref={option.value}
              option={option}
              key={option.value}
            />
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
}
