import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectedLine from "../../Assets/Images/SelectedLine.png";

export function PricingCard({ plan, isYearly, subscribedPlan }) {
  const {
    isRecommended,
    isContactUs,
    description,
    isMonthlyPlan,
    isYearlyPlan,
    monthlyAmonut,
    planKeywords,
    planName,
    yearlyAmonut,
    planId,
  } = plan;
  const navigate = useNavigate();
  const price = !isYearly ? monthlyAmonut : yearlyAmonut;
  const type = isYearly ? "year" : "month";
  const isCurrentPlan = planId === subscribedPlan?.planId;

  const features = planKeywords.sort((a, b) =>
    a.included === b.included ? 0 : a.included ? -1 : 1
  );



  const handleRedirection = () => {
    if (isContactUs) {
      navigate(
        `/contact-us?plan=${planName}&amount=${price}&planId=${planId}&type=${type}&contactUs=true`
      );
      return;
    }
    navigate(
      `${planName.toLowerCase()}?plan=${planName}&amount=${price}&planId=${planId}&type=${type}`
    );
  };
  return (
    <div
      className={`relative rounded-3xl py-10 px-8 w-80 ${
        isRecommended
          ? "bg-secondary text-white"
          : "bg-white border border-secondary"
      }`}
    >
      <div className="space-y-6">
        <div className="text-center">
          <h3
            className={`text-2xl font-bold mb-1 ${
              isRecommended ? "text-primary" : "text-secondary font-semibold"
            }`}
          >
            {planName}
          </h3>
          <p
            className={`text-sm h-10 ${
              isRecommended ? "text-primary" : "text-secondary font-semibold"
            }`}
          >
            {description}
          </p>
        </div>

        {isContactUs ? (
          <div className="flex items-baseline text-3xl font-bold justify-center h-20">
            Contact Us
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-20">
            <div className="flex items-baseline">
              <span
                className={`text-2xl font-bold ${
                  isRecommended
                    ? "text-primary"
                    : "text-secondary font-semibold"
                }`}
              >
                $
              </span>
              <span
                className={`text-6xl font-bold ${
                  isRecommended
                    ? "text-primary"
                    : "text-secondary font-semibold"
                }`}
              >
                {price}
              </span>
              <span
                className={`ml-1 ${
                  isRecommended
                    ? "text-primary"
                    : "text-secondary font-semibold"
                }`}
              >
                /{type}
              </span>
            </div>
            {isRecommended && (
              <div>
                <img
                  src={SelectedLine}
                  alt="Selected Line"
                  className="w-full"
                />
              </div>
            )}
          </div>
        )}

        <ul className="space-y-1 h-64">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-3">
              <div
                className={`w-3 h-3 rounded-full ${
                  feature.included
                    ? isRecommended
                      ? "bg-primary "
                      : "bg-secondary"
                    : isRecommended
                    ? "bg-primary opacity-55"
                    : "bg-secondary opacity-55"
                }`}
              />
              <span
                className={` ${
                  feature.included
                    ? isRecommended
                      ? "text-primary font-semibold"
                      : "text-secondary font-semibold"
                    : isRecommended
                    ? "text-primary font-semibold text-opacity-55"
                    : "text-secondary font-semibold text-opacity-55"
                }`}
              >
                {feature.text}
              </span>
            </li>
          ))}
        </ul>

        <Button
          disabled={isCurrentPlan}
          className={` py-2 px-8 w-full rounded-md transition-all duration-300 ${
            isRecommended
              ? "bg-white text-secondary hover:scale-110 hover:shadow-lg"
              : "border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white hover:scale-110 hover:shadow-lg"
          }`}
          onClick={handleRedirection}
        >
          {isCurrentPlan && "Current Plan"}
          {!isCurrentPlan &&
            (!isMonthlyPlan && !isYearlyPlan
              ? isContactUs
                ? "Contact us"
                : "Upgrade now"
              : "Upgrade Now")}
        </Button>
        {/* <Link
          to={"https://buy.stripe.com/test_bIY9CxcfJ0p9h0Y4gh"}
          target="_self"
          rel="noopener noreferrer"
        >
          checkout
        </Link> */}
      </div>
    </div>
  );
}
