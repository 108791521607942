import React from 'react'
// import RoleManagementTable from '../../Components/DataTable/RoleManagementTable'
import Container from '../../Components/UI/Container'
import RoleManagementTableFactored from '../../Components/DataTable/RoleManagementTableFactored'

const RoleManagment = () => {
  return (
    <Container>
        <RoleManagementTableFactored/>
    </Container>
  )
}

export default RoleManagment