import React from 'react';


export function PricingToggle({ isYearly, onChange }) {
  return (
    <div className="flex items-center justify-center gap-2">
      <span className={isYearly ? 'text-secondary font-semibold text-opacity-70' : 'text-secondary font-semibold '}>
      Monthly Billing
      </span>
      <button
        onClick={() => onChange(!isYearly)}
        className="w-12 h-6 bg-secondary rounded-full p-1 cursor-pointer"
      >
        <div
          className={`w-4 h-4 bg-debatePalette-background rounded-full transition-transform duration-200 ${
            isYearly ? 'translate-x-6' : 'translate-x-0'
          }`}
        />
      </button>
      <span className={isYearly ? 'text-secondary font-semibold' : 'text-secondary font-semibold text-opacity-70'}>
        Yearly Billing
      </span>
    </div>
  );
}